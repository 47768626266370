import React, { useState } from "react";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { CloseIcon } from "../../../helper/Icons";
import { Button } from "..";
import { errorNotification, successNotification } from "../Notification";
import { deleteCategoryApiHandler } from "../../../Service/category.service";
import { deleteReviewApiHandler, deleteServiceApiHandler } from "../../../Service/Services.service";
import { deleteTaxApiHandler } from "../../../Service/tax.service";
import { deleteBannerApiHandler } from "../../../Service/banner.service";
import { userDeleteHandler } from "../../../Service/Users.service";

const Delete = ({ handleClose, modalOpen, handelClick, closeFlag }) => {
  const [loading, setLoading] = useState(false);

  console.log("responseData-responseData", modalOpen);

  const agreeHandler = async () => {
    try {
      setLoading(true);
      if (modalOpen.name === "Category") {
        const { message, status } = await deleteCategoryApiHandler(
          modalOpen.id
        );
        if (status) {
          handleClose();
          closeFlag();
          successNotification(message);
        } else {
          errorNotification(message);
          handleClose();
          closeFlag();
        }
      }

      if (modalOpen.name === "Tax") {
        const { message, status } = await deleteTaxApiHandler(modalOpen.id);
        if (status) {
          handleClose();
          closeFlag();
          successNotification(message);
        } else {
          errorNotification(message);
          handleClose();
          closeFlag();
        }
      }

      if (modalOpen.name === "Banner") {
        const { message, status } = await deleteBannerApiHandler(modalOpen.id);
        if (status) {
          handleClose();
          closeFlag();
          successNotification(message);
        } else {
          errorNotification(message);
          handleClose();
          closeFlag();
        }
      }

      if (modalOpen.name === "Service") {
        const { message, status } = await deleteServiceApiHandler(modalOpen.id);
        if (status) {
          handleClose();
          closeFlag();
          successNotification(message);
        } else {
          errorNotification(message);
          handleClose();
          closeFlag();
        }
      }

      if (modalOpen.name === "Provider") {
        const { data, message, status } = await userDeleteHandler(modalOpen.id);
        if (status) {
          handleClose();
          closeFlag();
          successNotification(message);
        } else {
          errorNotification(message);
          handleClose();
          closeFlag();
        }
      }

      if (modalOpen.name === "Review") {
        const { data, message, status } = await deleteReviewApiHandler(modalOpen.id);
        if (status) {
          handleClose();
          closeFlag();
          successNotification(message);
        } else {
          errorNotification(message);
          handleClose();
          closeFlag();
        }
      }

      if (modalOpen.name === "Users") {
        const { data, message, status } = await userDeleteHandler(modalOpen.id);
        if (status) {
          handleClose();
          closeFlag();
          successNotification(message);
        } else {
          errorNotification(message);
          handleClose();
          closeFlag();
        }
      }
    } catch (err) {
      console.log("error", err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {modalOpen.head}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box width={{ sm: "400px" }}>
          <Typography fontSize={"18px"}>{modalOpen.para}</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          buttonName="No"
          size="small"
          color="white"
          onClick={handleClose}
        />
        <Button
          buttonName="Yes"
          size="small"
          disabled={loading}
          onClick={() => agreeHandler()}
        />
      </DialogActions>
    </>
  );
};

export default Delete;
