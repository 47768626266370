import React, { useEffect, useState } from "react";
import { getOrderListApiHandler } from "../../Service/Orders.service";
import { Button, Select, Modal } from "../../components/Common";
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Chip,
  Box,
  Typography,
} from "@mui/material";
import Heading from "../../components/Common/Heading";
import { Pagination, Search } from "../../components/Common/Table";
import TableRowsLoader from "../../components/Common/Loader/Skeleton";
import NoRecordFound from "../../components/Common/Table/NoRecordFound";
import moment from "moment";
import { notificationSuccess } from "../../state/Action/NotificationAction";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { useNavigate } from "react-router";
import { IoEyeOutline } from "react-icons/io5";
import { VscFilter } from "react-icons/vsc";
import StatusFilter from "../../components/Common/Filter/StatusFilter";
import { GiRoundStar } from "react-icons/gi";
import CommonButton from "../../components/Common/Button";

function Orders() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
    isEdit: false,
    isView: false,
    id: "",
  });
  const [providerId, setProviderId] = useState("");
  const [serviceId, setServiceId] = useState("");
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [orderList, setorderList] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [loading, setLoading] = useState(false);
  const [activeFlag, setActiveFlag] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [flag, setFlag] = useState("");
  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    getorderListHandler(
      providerId,
      serviceId,
      time,
      date,
      search,
      activeFlag,
      orderBy,
      flag,
      page
    );
  }, [search, page, activeFlag, providerId, serviceId, time, date]);

  const getorderListHandler = async (
    providerId,
    serviceId,
    time,
    date,
    search,
    activeFlag,
    orderBy,
    flag,
    page
  ) => {
    try {
      setLoading(true);
      const { data, message, status, total } = await getOrderListApiHandler(
        providerId,
        serviceId,
        time,
        date,
        search,
        activeFlag,
        orderBy,
        flag,
        page
      );
      if (status) {
        setorderList(data);
        setTotalData(total);
      } else {
        setorderList([]);
        setTotalData(0);
      }
    } catch (err) {
      console.log("error", err);
    } finally {
      setLoading(false);
    }
  };

  const pageChangeHandler = (data) => {
    setPage(data);
  };
  const closeFlagHandler = () => {
    getorderListHandler(
      providerId,
      serviceId,
      time,
      date,
      search,
      activeFlag,
      orderBy,
      flag,
      page
    );
  };

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };
  const resetHandler = () => {
    setActiveFlag("");
    setServiceId("");
    setTime("");
    setDate("");
  };

  const dataHadler = (data) => {
    console.log("data", data);
    setActiveFlag(data.orderStatus);
    setServiceId(data.selectedService);
    setTime(data.selectedTime);
    setDate(data.selectedDate);
  };

  return (
    <>
      <Stack
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        mb={1}
      >
        <Heading head="Orders" />
      </Stack>
      <Stack
        gap={1}
        mb={1}
        flexDirection={{ xs: "column-reverse", sm: "row" }}
        justifyContent={"space-between"}
        alignItems={{ xs: "end", sm: "center" }}
      >
        <Search search={search} setSearch={setSearch} />
        <Stack sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
          <Button
            buttonName="Export"
            size="small"
            onClick={(e) => {
              setIsModalOpen({
                open: true,
                currentComponent: "OrderDownload",
              });
            }}
          />
          <Button
            startIcon={<VscFilter />}
            onClick={() => toggleDrawer()}
            buttonName="Filter"
            color="white"
            size="small"
          />
          <StatusFilter
            openDrawer={openDrawer}
            toggleDrawer={toggleDrawer}
            resetHandler={resetHandler}
            dataHandler={dataHadler}
            ServiceFlag={true}
            TimeFlag={true}
            OrderStatusFlag={true}
            DateFlag={true}
            activeStatus={false}
          />
        </Stack>
      </Stack>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Order No.</TableCell>
              <TableCell>Service Name</TableCell>
              <TableCell>User Name</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Time</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Rating</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Payment Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRowsLoader rowsNum={10} />
            ) : orderList && orderList.length > 0 ? (
              orderList.map((response, i) => {
                const {
                  createdAt,
                  id,
                  name,
                  servicename,
                  status,
                  paymentStatus,
                  date,
                  time,
                  rating,
                  address,
                  orderNumber
                } = response;
                return (
                  <TableRow
                    key={i}
                    onClick={() => navigate("/view-order", { state: response })}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                  >
                    <TableCell>{orderNumber || "-"}</TableCell>
                    <TableCell>{servicename ? servicename : "-"}</TableCell>
                    <TableCell>{name}</TableCell>
                    <TableCell>{moment(date).format("DD-MM-YYYY")}</TableCell>
                    <TableCell>{time}</TableCell>
                    <TableCell>{address}</TableCell>
                    <TableCell>
                      <Stack flexDirection={'row'} gap={'3px'} alignItems={'center'} justifyContent={'center'}>
                        <Typography fontSize={'18px'}>{rating ? rating : "-"}</Typography>
                        <Box>{rating && <GiRoundStar style={{ color: '#FFCC00' }} />}</Box>
                      </Stack>
                    </TableCell>
                    {/* <TableCell>{status}</TableCell> */}
                    <TableCell onClick={(e) => e.stopPropagation()}>
                      {status == 1 ? (
                        <Select
                          className="table_select"
                          name="status"
                          value={status}
                          onChange={(e) => {
                            setIsModalOpen({
                              id: id,
                              status1: e.target.value,
                              status:
                                e.target.value == 1
                                  ? "Booked"
                                  : e.target.value == 2
                                    ? "Approved"
                                    : e.target.value == 3
                                      ? "Reject"
                                      : e.target.value == 4
                                        ? "Cancel"
                                        : e.target.value == 5
                                          ? "In-Progress"
                                          : e.target.value == 7
                                            ? "Pending"
                                            : "Complete",
                              open: true,
                              currentComponent: "OrderstatusChange",
                            });
                          }}
                          selectList={[
                            {
                              id: 1,
                              name: "Booked",
                            },
                            {
                              id: 2,
                              name: "Approved",
                            },
                            {
                              id: 3,
                              name: "Reject",
                            },
                          ]}
                        />
                      ) : status == 4 ? (
                        <Chip label="Cancelled" color="error" />
                      ) : status == 3 ? (
                        <Chip label="Rejected" color="warning" />
                      ) : status == 2 ? (
                        <Chip label="Approved" color="success" />
                      ) : status == 5 ? (
                        <Chip label="In-Progress" color="primary" />
                      ) : status == 7 ? (
                        <Chip label="Pending" color="primary" />
                      ) : (
                        <Chip label="Complete" color="success" />
                      )}
                    </TableCell>
                    <TableCell onClick={(e) => e.stopPropagation()}>
                      {paymentStatus == 0 ? (
                        <Chip label="Process" color="primary" />
                      ) : paymentStatus == 1 ? (
                        <Chip label="Complete" color="success" />
                      ) : paymentStatus == 2 ? (
                        <Chip label="Failed" color="error" />
                      ) : null}
                    </TableCell>


                    <TableCell onClick={(e) => e.stopPropagation()}>
                      <Stack
                        flexDirection={"row"}
                        gap={1}
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <IoEyeOutline
                          style={{ fontSize: "20px" }}
                          onClick={() =>
                            navigate("/view-order", { state: response })
                          }
                        />
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <NoRecordFound rowsNum={5} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        pageNumber={page}
        count={totalData}
        pageChangeHandler={pageChangeHandler}
      />
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "", isEdit: "" })
        }
        closeFlag={closeFlagHandler}
      />
    </>
  );
}

export default Orders;
