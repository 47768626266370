import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { checkPermission } from "../helper/Helper";

const PrivateRoute = ({ children, name, permissionStatus, ...props }) => {
  console.log("permissionStatus", permissionStatus);
  console.log("ROLE", localStorage.getItem("ROLE"));
  const persmission = checkPermission(
    permissionStatus,
    localStorage.getItem("ROLE")
  );
  console.log("checkPermission-data", persmission);

  const token = localStorage.getItem("token");
  // const routingStatus = persmission ?  token ? children : <Navigate to="/" />  : <h1> No permission Found</h1>
  const routingStatus = token ? (
    persmission ? (
      children
    ) : (
      <h1> no Permission Found</h1>
    )
  ) : (
    <Navigate to="/" />
  );

  return routingStatus;

  // return token ? children : <Navigate to="/" />;
};

export default PrivateRoute;
