export const loginInitialValues = {
  email: "",
  password: "",
  role: "",
  rememberMe: false,
};

export const providerLoginIv = {
  mobileNo: "",
  otp: "",
};

export const sendOtpIv = {
  mobileNo: "",
};

export const categoryIv = {
  name: "",
  sequence: "",
  image: "",
};
export const taxIv = {
  name: "",
  lable: "",
  description: "",
  taxValue: "",
};
export const serviceIv = {
  name: "",
  categoryId: "",
  cost: "",
  tax: "",
  // benefits: "",
  description: "",
  longDescription: "",
  image: [],
  machinery: [],
};

export const bannerIv = {
  bannerName: "",
  image: "",
  url: "",
  selectRedirection: "",
  startDate: "",
  endDate: "",
};

export const superAdminProfileIv = {
  firstName: "",
  lastName: "",
  email: "",
  profile: "",
};

export const bankDetailsIv = {
  acname: "",
  acno: "",
  reacno: "",
  isfccode: "",
  actype: ""
}

export const providerProfileIv = {
  firstName: "",
  lastName: "",
  email: "",
  profile: "",
  aadhar: "",
  pan: "",
  GSTNo: "",
  address: "",
  landmark: "",
  pincode: "",
  state: "",
  city: ""
};

export const changePasswordIv = {
  oldPassword: "",
  newPassword: "",
  confrim_Password: "",
};
