import { TableCell, TableRow } from "@mui/material";
import React from "react";

function NoRecordFound({ rowsNum }) {
  return (
    <TableRow>
      <TableCell
        sx={{
          color: "silver",
          textAlign: "center",
          paddingTop: "90px",
          borderBottom: "none",
          fontSize: "30px",
        }}
        colSpan={rowsNum ? rowsNum : 5}
      >
        No records to display
      </TableCell>
    </TableRow>
  );
}

export default NoRecordFound;
