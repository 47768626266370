import React from "react";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { CloseIcon } from "../../../helper/Icons";
import { Button } from "..";
import { userStatusUpdate } from "../../../Service/Users.service";
import { statusUpdateCategoryHandler } from "../../../Service/category.service";
import { statusUpdateServiceHandler } from "../../../Service/Services.service";
import { statusUpdateTaxHandler } from "../../../Service/tax.service";
import { statusUpdateBannerHandler } from "../../../Service/banner.service";

function StatusUpdate({ handleClose, modalOpen, handelClick, closeFlag }) {
  console.log("ModalOpen", modalOpen);

  const agreeHandler = async () => {
    if (modalOpen.name === "CATEGORY") {
      const { data, message, status } = await statusUpdateCategoryHandler(
        modalOpen.id
      );
      if (status) {
        closeFlag();
        handleClose();
      } else {
        handleClose();
      }
    }

    if (modalOpen.name === "BANNER") {
      const { data, message, status } = await statusUpdateBannerHandler(
        modalOpen.id
      );
      if (status) {
        closeFlag();
        handleClose();
      } else {
        handleClose();
      }
    }

    if (modalOpen.name === "TAX") {
      const { data, message, status } = await statusUpdateTaxHandler(
        modalOpen.id
      );
      if (status) {
        closeFlag();
        handleClose();
      } else {
        handleClose();
      }
    }
    if (modalOpen.name === "USER") {
      const { data, message, status } = await userStatusUpdate(modalOpen.id);
      if (status) {
        closeFlag();
        handleClose();
      } else {
        handleClose();
      }
    }

    if (modalOpen.name === "PROVIDER") {
      const { data, message, status } = await userStatusUpdate(modalOpen.id);
      if (status) {
        closeFlag();
        handleClose();
      } else {
        handleClose();
      }
    }

    if (modalOpen.name === "SERVICE") {
      const { data, message, status } = await statusUpdateServiceHandler(
        modalOpen.id
      );
      if (status) {
        closeFlag();
        handleClose();
      } else {
        handleClose();
      }
    }
  };

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {modalOpen.head}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box width={{ sm: "400px" }}>
          <Typography fontSize={"18px"}>{modalOpen.para}</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          buttonName="No"
          size="small"
          color="white"
          onClick={handleClose}
        />
        <Button buttonName="Yes" size="small" onClick={() => agreeHandler()} />
      </DialogActions>
    </>
  );
}

export default StatusUpdate;
