import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CloseIcon } from "../../helper/Icons";
import { Button, FileUpload, Input } from "../../components/Common";
import { useFormik } from "formik";
import { categoryIv } from "../../helper/initialValues";
import { categoryValidation } from "../../helper/validation";
import {
  EditCategoryApiHandler,
  addCategoryApiHandler,
} from "../../Service/category.service";
import { FaTrashCan } from "react-icons/fa6";
import { IoCamera } from "react-icons/io5";
import {
  errorNotification,
  successNotification,
} from "../../components/Common/Notification";
import { IoMdInformationCircleOutline } from "react-icons/io";

function AddCategory({ handleClose, modalOpen, closeFlag }) {
  console.log("ModalOpen", modalOpen);
  const componentStatus = modalOpen.componentStatus;
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [deleteImage, setDeleteImage] = useState([]);
  const isEdit = modalOpen?.isEdit;
  const isView = modalOpen?.isView;

  const categoryHandler = async () => {
    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("image", formik.values.image);
      formData.append("name", formik.values.name);
      formData.append("sequence", formik.values.sequence);
      const { data, message, status } =
        componentStatus === "ADD"
          ? await addCategoryApiHandler(formData)
          : await EditCategoryApiHandler(modalOpen?.data?.id, formData);

      if (status) {
        closeFlag();
        handleClose();
        successNotification(message);
      } else {
        errorNotification(message);
        // handleClose();
        // closeFlag();
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (componentStatus === "EDIT") {
      getDataHandler(modalOpen?.data);
    }
  }, []);

  const getDataHandler = (data) => {
    formik.setFieldValue("name", data?.response?.name);
    formik.setFieldValue("image", data?.response?.image);
    formik.setFieldValue("sequence", data?.response?.sequence);
    setFiles(data?.response?.name);
    setImage(data?.response?.image);
    setFiles(data?.response?.sequence);
  };

  const formik = useFormik({
    initialValues: categoryIv,
    validationSchema: categoryValidation,
    onSubmit: categoryHandler,
  });

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFiles(file);
      formik.setFieldValue("image", file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = () => {
    setImage(null);
    setFiles("");
    formik.setFieldValue("image", "");
  };

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {componentStatus === "ADD" ? "Add" : " Edit"} Category
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ width: { sm: "500px" } }}>
        <Grid container gap={2} paddingY={1}>
          <Grid item xs={12}>
            <Input
              labelinput="Name"
              formik={formik}
              name="name"
              maxLength={30}
            />
          </Grid>

          <Grid item xs={12}>
            <Input
              labelinput="Sequence"
              formik={formik}
              name="sequence"
              type={"number"}
              maxLength={30}
            />
          </Grid>

          <Grid item xs={12}>
            <Stack flexDirection={"row"} gap={"5px"} alignItems={"center"}>
              <Typography
                fontSize={{ xs: "13px", sm: "16px" }}
                fontWeight={500}
                mb={"2px"}
              >
                Image
              </Typography>
              <Tooltip title={"Recommended Banner Sizes: 300 x 160"}>
                <IconButton>
                  <IoMdInformationCircleOutline />
                </IconButton>
              </Tooltip>
            </Stack>
            <Box
              sx={{
                position: "relative",
                border: "1px solid rgba(0, 0, 0, 0.12)",
                borderRadius: "6px",
                width: "100px",
                height: "100px",
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {image ? (
                <div
                  className="cv_uploaded_image"
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="cv_uploaded_image_trash">
                    <FaTrashCan
                      style={{ position: "absolute", color: "#fff" }}
                      onClick={handleRemoveImage}
                    />
                  </div>
                  <img
                    src={image}
                    alt="Selected Image"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      zIndex: 1,
                      objectFit: "cover",
                    }}
                  />
                </div>
              ) : (
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <IoCamera
                    style={{ fontSize: "70px", color: "rgba(0, 0, 0, 0.12)" }}
                  />
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      cursor: "pointer",
                      opacity: "0",
                    }}
                  />
                </div>
              )}
            </Box>
            {formik.touched.image && formik.errors.image && (
              <FormHelperText sx={{ color: "red !important" }}>
                {formik.errors.image}
              </FormHelperText>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          buttonName="Cancel"
          size="small"
          color="white"
          onClick={handleClose}
        />
        <Button
          buttonName={"Save"}
          size="small"
          loading={loading}
          disabled={loading}
          onClick={formik.handleSubmit}
        />
      </DialogActions>
    </>
  );
}

export default AddCategory;
