import React, { useEffect, useState } from 'react'
import { BackButton, Heading } from '../../components/Common'
import { Avatar, Box, Divider, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { useLocation } from 'react-router-dom';
import { getUserDetailApiHandler } from '../../Service/Users.service';
import userDummy from '../../assets/images/user.jpg';
import moment from 'moment';
import ServiceproviderOrders from './ServiceproviderOrders';

const ProviderDetails = () => {
  const { state } = useLocation();
  const [user, setUser] = useState({});

  useEffect(() => {
    fetchUserData();
  }, [state]);

  const fetchUserData = async () => {
    const res = await getUserDetailApiHandler(state?.id);
    if (res?.status) {
      setUser(res.data);
    } else {
      setUser({});
    }
  };
  return (
    <div>
      <Stack
        flexDirection={"row"}
        justifyContent={"flex-start"}
        gap={1}
        alignItems={"center"}
        mb={2}
      >
        <BackButton />
        <Heading head="Provider Details" />
      </Stack>
      <Box className="card_container">
        <Stack gap={{ xs: 1, sm: 3, md: 5 }} flexDirection={{ md: 'row' }}>
          <Stack>
            <Avatar
              src={user?.profile ? user?.profile : userDummy}
              sx={{ width: 100, height: 100, }}
            />
          </Stack>
          <Stack gap={2}>
            <Stack flexDirection={{ sm: 'row' }} gap={{ xs: 1, sm: 3, md: 5 }}>
              <Stack>
                <Typography fontWeight={'500'} fontSize={{ sm: '18px' }} color={'#999'}>Name</Typography>
                <Typography fontWeight={'500'} fontSize={{ sm: '18px' }}>{user?.firstName} {user?.lastName}</Typography>
              </Stack>
              <Stack>
                <Typography fontWeight={'500'} fontSize={{ sm: '18px' }} color={'#999'}>Email Address</Typography>
                <Typography fontWeight={'500'} fontSize={{ sm: '18px' }}>{user?.email}</Typography>
              </Stack>
              <Stack>
                <Typography fontWeight={'500'} fontSize={{ sm: '18px' }} color={'#999'}>Phone No.</Typography>
                <Typography fontWeight={'500'} fontSize={{ sm: '18px' }}>{user?.mobileNo}</Typography>
              </Stack>
            </Stack>
            <Stack>
              <Typography fontWeight={'500'} fontSize={{ sm: '18px' }} color={'#999'}>Address</Typography>
              <Stack>
                {user?.addressData?.map((item, i) => {
                  const { name, address, city, state, pincode } = item;
                  return (
                    <Typography key={i} fontWeight={'500'} fontSize={{ sm: '18px' }}>{name}, {address}, {city}, {state}, {pincode}</Typography>
                  )
                })}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Divider sx={{ my: { xs: 2, sm: 3 } }} />
        <Stack>
          <Heading smallHead={'Services Details'} />
          <TableContainer component={Paper} style={{ border: '1px solid #00000012' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Service Name</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Category</TableCell>
                  {/* <TableCell>Provider Name</TableCell> */}
                  <TableCell>Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {user?.serviceData?.map((ele, i) => {
                  const { name, cost, categoryId, createdAt } = ele;
                  return (
                    <TableRow>
                      <TableCell>{name}</TableCell>
                      <TableCell>{cost}</TableCell>
                      <TableCell>{categoryId}</TableCell>
                      <TableCell>{moment(createdAt).format("DD-MM-YYYY")}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>

        <Stack style={{ marginTop: "20px" }}>
          <Heading smallHead={'Order Details'} />
          <ServiceproviderOrders orderList={user.orderData} />
        </Stack>
      </Box>
    </div>
  )
}

export default ProviderDetails