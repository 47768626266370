import React from "react";

function PageNotFound() {
  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h2>404 - Not Found</h2>
      <p>Sorry, the page you are looking for might be in another dimension!</p>
    </div>
  );
}

export default PageNotFound;
