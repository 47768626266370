import React, { useEffect, useState } from "react";
import { getUserListApiHandler } from "../../Service/Users.service";
import {
  Box,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Heading from "../../components/Common/Heading";
import { Pagination, Search } from "../../components/Common/Table";
import TableRowsLoader from "../../components/Common/Loader/Skeleton";
import NoRecordFound from "../../components/Common/Table/NoRecordFound";
import { Button, Modal } from "../../components/Common";
import { VscFilter } from "react-icons/vsc";
import { MdDeleteOutline } from "react-icons/md";
import StatusFilter from "../../components/Common/Filter/StatusFilter";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import moment from "moment";
import { IoEyeOutline } from "react-icons/io5";
import { Navigate, useNavigate } from "react-router-dom";

function Providers() {
  const navigate = useNavigate();
  const [role, setRole] = useState(2);
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
    isEdit: false,
    isView: false,
    id: "",
  });
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [userList, setuserList] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [loading, setLoading] = useState(false);
  const [activeFlag, setActiveFlag] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    getUserListHandler(role, search, activeFlag, orderBy, flag, page);
  }, [search, page, activeFlag, orderBy, flag]);

  const pageChangeHandler = (page) => {
    setPage(page);
  };

  const closeFlagHandler = () => {
    getUserListHandler(role, search, activeFlag, orderBy, flag, page);
  };

  const sortingHandler = (name, by) => {
    switch (name) {
      case "createdAt":
        setFlag((current) => !current);
        setOrderBy("createdAt");
        break;
      default:
        setFlag((current) => !current);
        setOrderBy("createdAt");
    }
  };

  const getUserListHandler = async (
    role,
    search,
    activeFlag,
    orderBy,
    flag,
    page
  ) => {
    try {
      setLoading(true);
      const { data, message, status, total } = await getUserListApiHandler(
        role,
        search,
        activeFlag,
        orderBy,
        flag,
        page
      );
      if (status) {
        setuserList(data);
        setTotalData(total);
      } else {
        setuserList([]);
        setTotalData(0);
      }
    } catch (err) {
      console.log("error", err);
    } finally {
      setLoading(false);
    }
  };

  const statusUpdateHandler = (id) => {
    setIsModalOpen({
      open: true,
      currentComponent: "STATUSUPDATE",
      para: "Are you sure want to change status ?",
      head: "Chanege Status",
      id: id,
      name: "PROVIDER",
    });
  };

  const resetHandler = () => {
    setSearch("");
    setActiveFlag("");
    setOrderBy("");
    setFlag(false);
    setPage(1);
  };

  const dataHadler = (data) => {
    setActiveFlag(data.status);
  };

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const deleteProviderHandler = async (id) => {
    setIsModalOpen({
      open: true,
      currentComponent: "Delete",
      para: "Are you sure want to remove this provider ?",
      head: "Remove Provider",
      id: id,
      name: "Provider",
    });
  };

  return (
    <>
      <Stack
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        mb={1}
      >
        <Heading head="Providers" />
      </Stack>
      <Stack
        mb={1}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        gap={1}
      >
        <Search search={search} setSearch={setSearch} />
        <Box display={'flex'} gap={1}>
          <Button
            buttonName="Export"
            size="small"
            onClick={(e) => {
              setIsModalOpen({
                open: true,
                currentComponent: "ExportUsers",
                role: 2
              });
            }}
          />
          <Button
            startIcon={<VscFilter />}
            onClick={() => toggleDrawer()}
            buttonName="Filter"
            color="white"
            size="small"
          />
        </Box>
        <StatusFilter
          openDrawer={openDrawer}
          toggleDrawer={toggleDrawer}
          resetHandler={resetHandler}
          dataHandler={dataHadler}
          activeStatus={true}
          DateRageFlag={true}
        />
      </Stack>
      <TableContainer component={Paper} >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Mobile Number</TableCell>
              <TableCell>Aadhar Number</TableCell>
              <TableCell onClick={() => sortingHandler("createdAt")}>
                Date
                {flag ? (
                  <FaArrowUp style={{ color: "#adadad" }} />
                ) : (
                  <FaArrowDown style={{ color: "#adadad" }} />
                )}
              </TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRowsLoader rowsNum={10} />
            ) : userList && userList.length > 0 ? (
              userList.map((response, i) => {
                const {
                  createdAt,
                  email,
                  id,
                  firstName,
                  lastName,
                  mobileNo,
                  status,
                  aadharNo
                } = response;
                return (
                  <TableRow
                    key={i}
                    // onClick={() =>
                    //   setIsModalOpen({
                    //     open: true,
                    //     currentComponent: "USERVIEW",
                    //     id: id,
                    //     componentStatus: "VIEW",
                    //   })
                    // }
                    onClick={() => navigate('/providers-details', { state: { id: id } })}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                  >
                    <TableCell>{firstName}</TableCell>
                    <TableCell>{lastName}</TableCell>
                    <TableCell>{email}</TableCell>
                    <TableCell>{mobileNo}</TableCell>
                    <TableCell>{aadharNo || "-"}</TableCell>
                    <TableCell>
                      {moment(createdAt).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell>
                      <Switch
                        checked={status === 1 ? true : false}
                        onChange={() => statusUpdateHandler(id)}
                        disabled={status === 2}
                        size='small'
                      />
                    </TableCell>
                    <TableCell onClick={(e) => e.stopPropagation()}>
                      <Stack
                        sx={{ flexDirection: "row", justifyContent: "center" }}
                        gap={1}
                      >
                        <IoEyeOutline
                          style={{ fontSize: "20px" }}
                          // onClick={() =>
                          //   setIsModalOpen({
                          //     open: true,
                          //     currentComponent: "USERVIEW",
                          //     id: id,
                          //     componentStatus: "VIEW",
                          //   })
                          // }
                          onClick={() => navigate('/providers-details', { state: { id: id } })}
                        />
                        <MdDeleteOutline
                          onClick={() => deleteProviderHandler(id)}
                          style={{ fontSize: "20px", cursor: "pointer" }}
                        />
                      </Stack>
                    </TableCell>
                    {/* <TableCell>{status}</TableCell> */}
                  </TableRow>
                );
              })
            ) : (
              <NoRecordFound rowsNum={5} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        pageNumber={page}
        count={totalData}
        pageChangeHandler={pageChangeHandler}
      />
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "", isEdit: "", role: "" })
        }
        closeFlag={closeFlagHandler}
      />
    </>
  );
}

export default Providers;
