import React, { useState, useEffect } from "react";
import {
  DialogActions,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CommonButton from "../Button";
import { ExportUsersData } from "../../../Service/Users.service";

const ExportUsers = ({ handleClose, modalOpen,values, search}) => {
    console.log("values========", modalOpen);

//   const [role, setRole] = useState();
//   const [search, setSearch] = useState(initialSearch || "");
//   const [userList, setUserList] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [activeFlag, setActiveFlag] = useState("");
//   const [orderBy, setOrderBy] = useState("");
//   const [flag, setFlag] = useState(false);
//   const [page, setPage] = useState(1);
//   const [totalData, setTotalData] = useState(0);

//   useEffect(() => {
//     getUserListHandler(role, search, activeFlag, orderBy, flag, page);
//   }, [search, page, activeFlag, orderBy, flag]);

//   const getUserListHandler = async (
//     role,
//     search,
//     activeFlag,
//     orderBy,
//     flag,
//     page
//   ) => {
//     try {
//       setLoading(true);
//       const { data, message, status, total } = await ExportUsersData(
//         role,
//         search,
//         activeFlag,
//         orderBy,
//         flag,
//         page
//       );
//       if (status) {
//         setUserList(data);
//         setTotalData(total);
//       } else {
//         setUserList([]);
//         setTotalData(0);
//       }
//     } catch (err) {
//       console.log("error", err);
//     } finally {
//       setLoading(false);
//     }
//   };

  const handleDownload = () => {
    const queryParams = new URLSearchParams(values).toString();
    const searchString = search ? `&search=${search}` : "";
    window.location.href = `https://apiondemd.mobiginie.com/api/v1/exportUserData?${queryParams}&role=${modalOpen.role}${searchString}`;
    handleClose();
  };

  return (
    <div>
      <form>
        <DialogTitle sx={{ m: 2, p: 3 }} id="Common_modal">
          {"Do you want to download this file?"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogActions>
          <CommonButton
            buttonName="Yes"
            onClick={(e) => {
              e.preventDefault();
              handleDownload();
            }}
          />
          <CommonButton buttonName="Cancel" onClick={handleClose} />
        </DialogActions>
      </form>
    </div>
  );
};

export default ExportUsers;
