import { LOGIN_SUCCESS, LOGOUT, EXPIRED } from '../Action-type/authActionType';


const initialState = {
  user: {},
  isAuthenticate: false,
  isExpired: false,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isExpired: false,
        user: action.payload || {},
        isAuthenticate: true,
      };
    case LOGOUT:
      return {
        ...state,
        user: {},
        isExpired: false,
        isAuthenticate: false,
      };
    case EXPIRED:
      return {
        ...state,
        user: {},
        isAuthenticate: false,
        isExpired: true,
      };
    default:
      return state;
  }
}
