import { RxDashboard } from "react-icons/rx";
import { BiBox } from "react-icons/bi";
import {
  MdKeyboardArrowRight,
  MdOutlineCategory,
  MdOutlineManageAccounts,
  MdOutlinePayments,
} from "react-icons/md";
import {
  TbBuildingSkyscraper,
  TbFileInvoice,
  TbNotebook,
  TbTicket,
} from "react-icons/tb";
import { PiWarehouse } from "react-icons/pi";
import { HiOutlineUsers } from "react-icons/hi";
import { GrLocation } from "react-icons/gr";
import { FiBox } from "react-icons/fi";
import { SlNotebook } from "react-icons/sl";
import { LuShoppingCart } from "react-icons/lu";
import { FaRegAddressBook } from "react-icons/fa";

export const SidebarList = [
  {
    name: "Dashboard",
    icon: <RxDashboard style={{ color: "#000", fontSize: "22px" }} />,
    activeIcon: <RxDashboard style={{ color: "#fff", fontSize: "22px" }} />,
    path: "/dashboard",
    SUBMENU: false,
  },
];

export const categoryList = [
  { name: "Category 1", value: "Category 1" },
  { name: "Category 2", value: "Category 2" },
];

export const subCategoryList = [
  { name: "Sub Category 1", value: "Sub Category 1" },
  { name: "Sub Category 2", value: "Sub Category 2" },
];

export const productType = [
  { name: "Product Type 1", value: "Product Type 1" },
  { name: "Product Type 2", value: "Product Type 2" },
];

export const status = [
  { name: "Active", value: "Active" },
  { name: "Inactive", value: "Inactive" },
];

export const statusFilter = [
  { name: "Active", id: "1" },
  { name: "Inactive", id: "0" },
  { name: "Deleted", id: "2" },
];
export const AppoinmentstatusFilter = [
  { name: "Booked", id: "1" },
  { name: "Approve", id: "2" },
  { name: "Reject", id: "3" },
  { name: "Cancel", id: "4" },
  { name: "In-Progress", id: "5" },
  { name: "Complete", id: "6" },
];
export const appintmentTypeList = [
  {
    name: "Bulk Order",
    value: 1,
    id: "1",
  },
  { name: "Expert Consultation", value: 2, id: "2" },
];

export const discountTypeList = [
  { name: "Flat", value: "Flat", id: "0" },
  { name: "Percentage", value: "Percentage", id: "1" },
];

export const couponTypeList = [
  { name: "User", value: "User", id: "1" },
  { name: "Public", value: "Public", id: "0" },
];

export const checkPermission = (permissonData, role) => {
  const userRole = localStorage.getItem("ROLE");
  return permissonData.includes(role || userRole);
};

export const SUPERADMIN = "1";

export const PROVIDER = "2";
