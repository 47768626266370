import React, { useEffect, useState } from "react";
import { Avatar, Box, Divider, Chip, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import moment from 'moment';
import { useNavigate } from "react-router";
import { IoEyeOutline } from "react-icons/io5";

const ServiceproviderOrders = ({ orderList }) => {

    const navigate = useNavigate();

    return (
        <>
            <TableContainer component={Paper} style={{ border: '1px solid #00000012' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Order No.</TableCell>
                            <TableCell>Service Name</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Time</TableCell>
                            <TableCell>Location</TableCell>
                            <TableCell>Rating</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Payment Status</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orderList?.map((ele, i) => {
                            const { orderNumber, address, date, time, rating, status, paymentStatus, servicename, createdAt } = ele;
                            return (
                                <TableRow>
                                    <TableCell>{orderNumber || "-"}</TableCell>
                                    <TableCell>{servicename || "-"}</TableCell>
                                    <TableCell>{date || "-"}</TableCell>
                                    <TableCell>{time || "-"}</TableCell>
                                    <TableCell>{address || "-"}</TableCell>
                                    <TableCell>{rating || "-"}</TableCell>
                                    <TableCell>

                                        {status == 1 ? (
                                            <Chip label="Booked" color="primary" />
                                        ) : status == 4 ? (
                                            <Chip label="Cancelled" color="error" />
                                        ) : status == 3 ? (
                                            <Chip label="Rejected" color="warning" />
                                        ) : status == 2 ? (
                                            <Chip label="Approved" color="success" />
                                        ) : status == 5 ? (
                                            <Chip label="In-Progress" color="primary" />
                                        ) : status == 7 ? (
                                            <Chip label="Pending" color="primary" />
                                        ) : (
                                            <Chip label="Complete" color="success" />
                                        )}

                                    </TableCell>
                                    <TableCell>
                                        {paymentStatus == 0 ? (
                                            <Chip label="Process" color="primary" />
                                        ) : paymentStatus == 1 ? (
                                            <Chip label="Complete" color="success" />
                                        ) : paymentStatus == 2 ? (
                                            <Chip label="Failed" color="error" />
                                        ) : null}
                                    </TableCell>
                                    <TableCell>
                                        <Stack
                                            flexDirection={"row"}
                                            gap={1}
                                            alignItems={"center"}
                                            justifyContent={"center"}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <IoEyeOutline
                                                style={{ fontSize: "20px" }}
                                                onClick={() =>
                                                    navigate("/view-order", { state: ele })
                                                }
                                            />
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )

}

export default ServiceproviderOrders

