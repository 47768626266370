import { NOTIFICATION_SUCCESS } from "../Action-type/Notification-actiontype";


const initialState = {
  show: {
    show: false,
    type: '',
    message: ''
  }
};

export const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATION_SUCCESS:
      return {
        ...state,
        show: action.payload
      };
    default:
      return state;
  }
}
