import { SUPERADMIN } from "../helper/Helper";
import { deleteRequest, get, post, put } from "../web.request";

export const getAllServiceAllApiHandler = (
  search,
  status,
  page,
  categoryId,
  orderBy,
  flag
) => {
  const userId = localStorage.getItem("USERID");
  const rolewise =
    localStorage.getItem("ROLE") === SUPERADMIN
      ? `serviceList?search=${search}&status=${status}&orderBy=${orderBy}&sortFlag=${flag}&page=${page}&categoryId=${categoryId}`
      : `serviceList?search=${search}&status=${status}&orderBy=${orderBy}&providerId=${userId}&sortFlag=${flag}&page=${page}&categoryId=${categoryId}`;
  return get(rolewise);
};

export const addServiceApiHandler = (payload) => {
  return post(`createService`, payload);
};

export const EditServiceApiHandler = (id, payload) => {
  return put(`updateService/${id}`, payload);
};

export const deleteServiceApiHandler = (id) => {
  return deleteRequest(`deleteService/${id}`);
};

export const deleteReviewApiHandler = (id) => {
  return deleteRequest(`deleteReview/${id}`);
};

export const viewServiceApiHandler = (id) => {
  return get(`serviceDetail/${id}`);
};

export const getPlatformFeeApiHandler = () => {
  return get(`getPlatformFee`);
};
export const statusUpdateServiceHandler = (id) => {
  return put(`statusChangeService/${id}`);
};

export const getVerifyAddress = (id) => {
  return get(`addressvalidation`);
};
