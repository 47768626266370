import React from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { CloseIcon } from "../../../helper/Icons";
import { changePasswordIv } from "../../../helper/initialValues";
import { changePasswordValidation } from "../../../helper/validation";
import { useFormik } from "formik";
import { Button, Input } from "..";
import { chnagePasswordHandler } from "../../../Service/Users.service";
import { errorNotification, successNotification } from "../Notification";

function ChangePassword({ handleClose }) {
  const [loading, setLoading] = React.useState(false);
  const userId = localStorage.getItem("USERID");
  const submitHandler = async () => {
    try {
      setLoading(true);
      const payload = {
        oldPassword: formik.values.oldPassword,
        newPassword: formik.values.newPassword,
      };
      const { data, message, status } = await chnagePasswordHandler(
        userId,
        payload
      );
      if (status) {
        handleClose();
        successNotification(message);
      } else {
        handleClose();
        errorNotification(message);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: changePasswordIv,
    validationSchema: changePasswordValidation,
    onSubmit: submitHandler,
  });
  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        Change Password
      </DialogTitle>

      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Grid
          container
          gap={2}
          width={{ sm: "400px" }}
          paddingY={1}
        >
          <Grid item xs={12}>
            <Input
              placeholder="Current Password"
              passwordinput
              formik={formik}
              name="oldPassword"
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              placeholder="New Password"
              passwordinput
              formik={formik}
              name="newPassword"
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              placeholder="Confirm Password"
              passwordinput
              formik={formik}
              name="confrim_Password"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          buttonName="Cancel"
          size="small"
          color="white"
          onClick={handleClose}
        />
        <Button
          buttonName="save"
          size="small"
          disabled={loading}
          // color="white"
          onClick={() => formik.handleSubmit()}
        />
      </DialogActions>
    </>
  );
}

export default ChangePassword;
