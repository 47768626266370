import * as Yup from "yup";

export const loginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address.")
    .required("Email is required."),
  password: Yup.string()
    .required("Password is required.")
    .min(8, "Password must be at least 8 characters."),
});

export const providerLoginValidationSchema = Yup.object().shape({
  mobileNo: Yup.string()
    .required("Mobile number is required.")
    .matches(/^[0-9]{10}$/, "Invalid mobile number."),
  otp: Yup.string()
    .required("OTP is required.")
    .matches(/^[0-9]{4}$/, "Invalid OTP."),
});

export const sendOtpValidationSchema = Yup.object().shape({
  mobileNo: Yup.string()
    .required("Mobile number is required.")
    .matches(/^[0-9]{10}$/, "Invalid mobile number."),
});

export const categoryValidation = Yup.object().shape({
  name: Yup.string().required("Name is required."),
  sequence: Yup.number().required("Sequence is required."),
  image: Yup.string().required("Image is required."),
});

export const taxValidation = Yup.object().shape({
  name: Yup.string().required("Name is required."),
  lable: Yup.string().required("Lable is required."),
  taxValue: Yup.number().required("TaxValue is required."),
  description: Yup.string().required("Description is required."),
});

export const orderOtpValidation = Yup.object().shape({
  otp: Yup.string()
    .required("OTP is required.")
    .matches(/^[0-9]{4}$/, "Invalid OTP."),
});

export const addServiceValidation = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  categoryId: Yup.string().required("Category is required"),
  tax: Yup.string().required("Tax is required"),
  cost: Yup.number().required("Cost is required"),
  // benefits: Yup.string().required("Benefits is required"),
  description: Yup.string().required("Description is required"),
  // longDescription: Yup.string().required("Long Description is required"),
  //   image: Yup.mixed().when('Componentstatus', {
  //   is: (value) => value === 'ADD',
  //   then: Yup.mixed().required('Image is required'),
  //   otherwise: Yup.mixed(),
  // }),
  machinery: Yup.array().min(1, "At least one machinery is required"),
});

export const bannerValidation = Yup.object().shape({
  bannerName: Yup.string().required("Banner name is required"),
  url: Yup.string().when("selectRedirection", (value, schema) => {
    return value[0] && value[0] === "1"
      ? schema.required("Url is required")
      : schema;
  }),
  selectRedirection: Yup.string().required("Redirection is required"),
  startDate: Yup.date().required("start date is required"),
  endDate: Yup.date().required("End date is required"),
  image: Yup.string().required("Image is required."),
});

export const superAdminProfileValidation = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string()
    .trim()
    .matches(
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
      "Invalid email format"
    )
    .required("Email is required"),
  profile: Yup.string().required("Profile is required"),
  aadhar: Yup.string()
    .trim()
    .matches(
      /^[0-9]{4}[ -]?[0-9]{4}[ -]?[0-9]{4}$/,
      "Invalid aadhar format"
    )
    .required("Aadhar is required"),
  pan: Yup.string()
    .trim()
    .matches(
      /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
      "Invalid PAN format"
    )
    .required("PAN card is required"),

  GSTNo: Yup.string()
    .trim()
    .matches(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z1-9]{1}Z[A-Z0-9]{1}$/,
      "Invalid GST format"
    )
    .required("GST number is required"),

});

export const BankDetailsSPValidation = Yup.object().shape({
  acname: Yup.string().required("Acount Holder Name is required"),
  // isfccode: Yup.string().required("ISFC code is required"),
  ifsc: Yup.string()
    .required("IFSC code is required.")
    .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, "Invalid IFSC code. Please check the format."),
  actype: Yup.string().required("Account type is required"),
  acno: Yup.string()
    .required("Bank Account Number is required.")
    .matches(/^[0-9]+$/, "Invalid Bank Account number. Only numbers are allowed.")
    .min(8, "Bank Account Number must be at least 8 digits.") // Minimum 8 digits
    .max(16, "Bank Account Number cannot exceed 16 digits."), // Maximum 16 digits

  reacno: Yup.string()
    .required("Please confirm your Bank Account Number.")
    .matches(/^[0-9]+$/, "Invalid Bank Account number. Only numbers are allowed.")
    .min(8, "Bank Account Number must be at least 8 digits.") // Minimum 8 digits
    .max(16, "Bank Account Number cannot exceed 16 digits.")
    .oneOf([Yup.ref('acno'), null], 'Bank Account Number must match.'),
})

export const ProviderProfileValidation = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string()
    .trim()
    .matches(
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
      "Invalid email format"
    )
    .required("Email is required"),
  profile: Yup.string().required("Profile is required"),
  aadhar: Yup.string()
    .trim()
    .matches(
      /^[0-9]{4}[ -]?[0-9]{4}[ -]?[0-9]{4}$/,
      "Invalid aadhar format"
    )
    .required("Aadhar is required"),
  pan: Yup.string()
    .trim()
    .matches(
      /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
      "Invalid PAN format"
    )
    .required("PAN card is required"),

  GSTNo: Yup.string()
    .trim()
    .matches(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z1-9]{1}Z[A-Z0-9]{1}$/,
      "Invalid GST format"
    )
    .optional(),
  address: Yup.string().required("Address is required"),
  landmark: Yup.string().required("Landmark is required"),
  pincode: Yup.string()
    .required("Pincode number is required.")
    .matches(/^[0-9]{6}$/, "Invalid Pincode number."),
  state: Yup.string().required("State is required"),
  city: Yup.string().required("City is required"),
});

export const changePasswordValidation = Yup.object().shape({
  oldPassword: Yup.string().trim().required("Password is required"),
  newPassword: Yup.string()
    .trim()
    .required("Password is required")
    .matches(
      /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/,
      "Password must be 6-16 characters long and contain at least one number and one special character (!@#$%^&*)"
    ),
  confrim_Password: Yup.string()
    .trim()
    .required("Confirm password is required")
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
});
