import React from 'react'
import { Box, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Skeleton, Typography } from '@mui/material'
import { CloseIcon } from '../../../helper/Icons'
import { Button } from '../../../components/Common'
import { OrderStatusChange } from "../../../Service/Orders.service";
import { notificationSuccess } from "../../../state/Action/NotificationAction";
import { useDispatch } from 'react-redux'
import { errorNotification, successNotification } from '../Notification';

const StatusChange = ({ handleClose, formik, modalOpen, isLoading, closeFlag }) => {
  const dispatch = useDispatch();

  const StatusChange = async () => {
    const payload = {
      status: modalOpen.status1,
    }

    const res = await OrderStatusChange(modalOpen.id, payload);
    if (res?.status) {
      handleClose();
      closeFlag();
      successNotification(res?.message);


    } else {
      handleClose();
      closeFlag();
      errorNotification(res?.message);

    }


  }

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        Status Change to {modalOpen.status}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box width={{ sm: '400px' }}>
          <Typography fontSize={{ sm: '18px' }}></Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button buttonName={'Cancel'} size='small' color='white' onClick={handleClose} />
        <Button buttonName={'Save'}
          size='small' onClick={StatusChange} disabled={isLoading} />
      </DialogActions>
    </>
  )
}

export default StatusChange