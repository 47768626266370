import { deleteRequest, get, post, put } from "../web.request";
export const getAllCategoryApiHandler = (
  search,
  status,
  page,
  orderBy,
  flag
) => {
  return get(
    `categoryList?search=${search}&status=${status}&orderBy=${orderBy}&sortFlag=${flag}&page=${page}`
  );
};

export const addCategoryApiHandler = (payload) => {
  return post(`createCategory`, payload);
};

export const EditCategoryApiHandler = (id, payload) => {
  return put(`updateCategory/${id}`, payload);
};

export const deleteCategoryApiHandler = (id) => {
  return deleteRequest(`deleteCategory/${id}`);
};

export const listCategoryHandler = () => {
  return get(`categoryDropdown`);
};

export const statusUpdateCategoryHandler = (id) => {
  return put(`statusChangeCategory/${id}`);
};
