import axios from "axios";
import { deleteRequest, get, post, put } from "../web.request";

export const getUserListApiHandler = (
  role,
  search,
  status,
  orderBy,
  flag,
  page
) => {
  return get(
    `providerList?role=${role}&search=${search}&status=${status}&orderBy=${orderBy}&sortFlag=${flag}&page=${page}`
  );
  // search&status&orderBy&sortFlag&page&perPageSize
};

export const ExportUsersData = (
  role,
  search,
  status,
  orderBy,
  flag,
) => {
  return get(
    `exportUserData?role=${role}&search=${search}&status=${status}&orderBy=${orderBy}&sortFlag=${flag}`
  );
  // search&status&orderBy&sortFlag&page&perPageSize
};
export const userStatusUpdate = (id) => {
  return put(`statusChangeProvider/${id}`);
};

export const getUserDetailApiHandler = (id) => {
  return get(`providerDetail/${id}`);
};

export const getProviderProfile = (id) => {
  return get(`getproviderprofile/${id}`);
};

export const superAdminProfileUpdateApiHandler = (id, payload) => {
  return put(`profileUpdate/${id}`, payload);
};

export const servicePProfileUpdateApiHandler = (id, payload) => {
  return put(`profileUpdateprovider/${id}`, payload);
};

export const chnagePasswordHandler = (id, payload) => {
  return put(`changePassword/${id}`, payload);
};

export const userDeleteHandler = (id) => {
  return deleteRequest(`deleteProvider/${id}`);
};

export const getPincodeDetails = async (pincode) => {
  return await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${pincode}&key=AIzaSyDUSiOex3P2Vg8ishezBnHur46FdKZUeyI`);
}

export const handleBankDetails = (payload) => {
  return post(`bankdetails`, payload);
};
