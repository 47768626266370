import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BackButton, Button, Heading, Input } from "../../components/Common";
import { FaRegUser } from "react-icons/fa";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { FaTrashCan } from "react-icons/fa6";
import { IoCamera } from "react-icons/io5";
import {
  getUserDetailApiHandler,
  superAdminProfileUpdateApiHandler,
} from "../../Service/Users.service";
import { useFormik } from "formik";
import { superAdminProfileValidation } from "../../helper/validation";
import { superAdminProfileIv } from "../../helper/initialValues";
import {
  errorNotification,
  successNotification,
} from "../../components/Common/Notification";

function Profile() {
  const [image, setImage] = useState(null);
  const [userDetails, setUserDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [edit, setEdit] = useState();
  const handleClick = () => setEdit((show) => !show);
  const userId = localStorage.getItem("USERID");

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFiles(file);
      formik.setFieldValue("profile", file);
      // formik.setFieldValue("image", file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const getUserDetails = async (userId) => {
    try {
      setLoading(true);
      const { data, message, status, total } = await getUserDetailApiHandler(
        userId
      );
      if (status) {
        setUserDetails(data);
        const userData = data;
        formik.setValues({
          ...formik.values,
          firstName: userData?.firstName ?? "",
          lastName: userData?.lastName ?? "",
          email: userData?.email ?? "",
          pan: userData?.panNo ?? "",
          GSTNo: userData?.GSTNo ?? "",
          aadhar: userData?.aadharNo ?? "",
          mobile: userData?.mobileNo ?? "",
          profile: userData?.profile ?? "",
        });
        setImage(userData?.profile);
        setEdit(false)
      } else {
        errorNotification(message);
      }
    } catch (e) {
      console.log(e);
      errorNotification("Something goes wrong");
    } finally {
      setLoading(false);
    }
  };

  const profileUpdateHandler = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("firstName", formik.values.firstName);
      formData.append("lastName", formik.values.lastName);
      formData.append("email", formik.values.email);
      formData.append("mobile", formik.values.mobile);
      formData.append("aadharNo", formik.values.aadhar);
      formData.append("panNo", formik.values.pan);
      formData.append("GSTNo", formik.values.GSTNo);
      formData.append("profile", files);

      const { data, message, status } = await superAdminProfileUpdateApiHandler(
        userDetails.id,
        formData
      );
      if (status) {
        getUserDetails(userId);
        successNotification(message);
      } else {
        errorNotification(message);
      }
    } catch (err) {
      console.log("error", err);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: superAdminProfileIv,
    validationSchema: superAdminProfileValidation,
    onSubmit: profileUpdateHandler,
  });

  console.log("userDetails", userDetails);
  console.log("formik", formik.values);

  useEffect(() => {
    getUserDetails(userId);
  }, []);

  const handleRemoveImage = () => {
    setImage(null);
    setFiles("");
    formik.setFieldValue("profile", "");
    // formik.setFieldValue("image", "");
  };
  return (
    <>
      <Stack justifyContent={"flex-start"}>
        <Stack flexDirection={"row"} gap={1} alignItems={"center"} mb={1}>
          <BackButton />
          <Heading head={"Profile"} />
        </Stack>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Box sx={{ border: "1px solid #eee", borderRadius: "16px", backgroundColor: 'white.main' }} p={{ xs: 3, sm: 4 }}>
              <Grid container spacing={{ xs: 2, sm: 3 }}>
                <Grid item xs={12}>
                  <Grid container sx={{ width: { xs: "100%", sm: "100%" } }}>
                    <Grid
                      item
                      xs={12}
                      display={"flex"}
                      justifyContent={"center"}
                    >
                      <Box
                        sx={{
                          position: "relative",
                          border: "1px solid rgba(0, 0, 0, 0.12)",
                          borderRadius: "50%",
                          width: "100px",
                          height: "100px",
                          overflow: "hidden",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {image ? (
                          <div
                            className="cv_uploaded_image"
                            style={{
                              position: "relative",
                              width: "100%",
                              height: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div className="cv_uploaded_image_trash">
                              <FaTrashCan
                                style={{ position: "absolute", color: "#fff" }}
                                onClick={handleRemoveImage}
                              />
                            </div>
                            <img
                              src={image}
                              alt="Selected Image"
                              style={{
                                maxWidth: "100%",
                                maxHeight: "100%",
                                zIndex: 1,
                                objectFit: "cover",
                              }}
                            />
                          </div>
                        ) : (
                          <div
                            style={{
                              position: "relative",
                              width: "100%",
                              height: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <FaRegUser
                              style={{
                                fontSize: "50px",
                                color: "rgba(0, 0, 0, 0.12)",
                              }}
                            />
                            <input
                              type="file"
                              accept="image/*"
                              onChange={handleFileChange}
                              style={{
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                cursor: "pointer",
                                opacity: "0",
                              }}
                            />
                          </div>
                        )}
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      display={"flex"}
                      justifyContent={"center"}
                      marginTop={1}
                    >
                      <Typography fontSize={"18px"} fontWeight={500}>
                        {userDetails.firstName ? userDetails.firstName : "-"}{" "}
                        {userDetails.lastName ? userDetails.lastName : "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Input
                    labelinput="First Name"
                    name="firstName"
                    formik={formik}
                    disabled={edit ? false : true}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Input
                    labelinput="Last Name"
                    name="lastName"
                    formik={formik}
                    disabled={edit ? false : true}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Input
                    labelinput="Email Address"
                    name="email"
                    formik={formik}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Input
                    labelinput="Mobile Number"
                    name="mobile"
                    formik={formik}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Input
                    labelinput="Aadhar Number"
                    name="aadhar"
                    formik={formik}
                    disabled={edit ? false : true}
                    maxLength={12}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Input
                    labelinput="Pan Number"
                    name="pan"
                    formik={formik}
                    disabled={edit ? false : true}
                    maxLength={10}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Input
                    labelinput="GST Number"
                    name="GSTNo"
                    formik={formik}
                    disabled={edit ? false : true}
                    maxLength={20}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    gap={1}
                    flexDirection={"row"}
                    justifyContent={"flex-end"}
                  >
                    {edit ? (
                      <>
                        <Button
                          buttonName="Cancel"
                          disabled={loading}
                          onClick={handleClick}
                        />
                        <Button
                          buttonName="Save"
                          disabled={loading}
                          onClick={() => profileUpdateHandler()}
                        />
                      </>
                    ) : (
                      <Button
                        buttonName="Edit"
                        disabled={loading}
                        onClick={handleClick}
                      />
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
}

export default Profile;
