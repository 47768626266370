import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CloseIcon } from "../../../helper/Icons";
import { Button, Input, Modal } from "..";
import { useFormik } from "formik";
import { categoryIv } from "../../../helper/initialValues";
import { orderOtpValidation } from "../../../helper/validation";
import {
  OrderDetail,
  SendServiceStartOtp,
} from "../../../Service/Orders.service";
import {
  errorNotification,
  successNotification,
} from "../Notification/index";
import OTPInput from "react-otp-input";

function OrderStartOtpSend({ handleClose, modalOpen, closeFlag }) {
  const [otp, setOtp] = useState("");
  const [otpFlag, setOtpFlag] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
    isEdit: false,
    isView: false,
    id: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (modalOpen && modalOpen?.id) {
      purchaseOrderData(modalOpen?.id);
    }
  }, []);

  const purchaseOrderData = async (id) => {
    const res = await OrderDetail(id);
  };
  const closeFlagHandler = () => {
    purchaseOrderData(modalOpen?.id);
  };

  const otpSendHandler = async () => {
    try {
      setLoading(true);

      const payload = {
        otp: otp
      }

      const { data, message, status } =
        await SendServiceStartOtp(modalOpen?.id);

      if (status) {
        // closeFlag();
        successNotification(message);
        setTimeout(() => {
          setIsModalOpen({
            open: true,
            currentComponent: "OTPSTARTVERIFY",
            id: modalOpen?.id, // Assuming `modalOpen` contains the order ID,

          });
        }, 2000);

      } else {
        handleClose();
        closeFlag();
        errorNotification(message);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        Are you sure, you want to start service
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ width: { sm: "500px" } }}>
        <Grid container gap={2} paddingY={1}>
          <Grid item xs={12}>
            {/* <Input
              labelinput="OTP"
              formik={formik}
              name="otp"
              maxLength={50}
            /> */}
            {/* <div className="otp_input">
              <OTPInput
                value={otp}
                disabled
                onChange={(event) => [
                  setOtp(event),
                  setOtpFlag(true)
                ]}
                inputType="number"
                numInputs={4}
                // renderSeparator={<span style={{ padding: '0 10px' }}>-</span>}
                renderInput={(props) => <input {...props} style={{ width: '50px', border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: '7px', textAlign: 'center', padding: '8px 10px', }} />}
              />
            </div> */}
            {/* <div>
              <Button
                buttonName="Send OTP to Client"
                size="small"
                color="white"
                disabled={loading}
                onClick={() => otpSendHandler()}

              />
            </div> */}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          buttonName="No"
          size="small"
          color="white"
          disabled={loading}
          onClick={handleClose}
        />
        <Button
          buttonName="Yes"
          size="small"
          color="white"
          disabled={loading}
          onClick={() => {
            otpSendHandler();
          }}
        />
      </DialogActions>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() => {
          setIsModalOpen({ open: false, currentComponent: "", isEdit: "" })
          handleClose()
        }
        }
        closeFlag={closeFlagHandler}

      />
    </>
  );
}

export default OrderStartOtpSend;
