import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { ChangePassword, Delete, Logout } from "./modals";
import AddCategory from "../../pages/Category/AddCategory";
import AddTax from "../../pages/Tax/AddTax";
import StatusUpdate from "./modals/StatusUpdate";
import StatusChange from "./modals/StatusChange";
import OrderOtp from "./modals/OrderOtpVerify";
import AddBanner from "../../pages/Banner/AddBanner";
import ProviderDetails from "../../pages/Providers/ProviderView";
import OrderStartOtpSend from "./modals/OrderStartOtpSend";
import OrderEndOtpSend from "./modals/OrderEndOtpSend";
import OrderEndOtpVerify from "./modals/OrderEndOtpVerify";
import OrderDownload from "./modals/orderDownload";
import ExportUsers from "./modals/exportUsers";

export default function CommonModal({
  handleClose,
  modalOpen,
  handelClick,
  closeFlag,
}) {
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="Common_modal"
      open={modalOpen.open}
    >
      {modalOpen.currentComponent === "Delete" && (
        <Delete
          handleClose={handleClose}
          modalOpen={modalOpen}
          handelClick={handelClick}
          closeFlag={closeFlag}
        />
      )}
      {modalOpen.currentComponent === "logout" && (
        <Logout
          handleClose={handleClose}
          handelClick={handelClick}
          modalOpen={modalOpen}
          closeFlag={closeFlag}
        />
      )}

      {modalOpen.currentComponent === "ADDCATEGORY" && (
        <AddCategory
          handleClose={handleClose}
          handelClick={handelClick}
          modalOpen={modalOpen}
          closeFlag={closeFlag}
        />
      )}

       {modalOpen.currentComponent === "ADDBANNER" && (
        <AddBanner
          handleClose={handleClose}
          handelClick={handelClick}
          modalOpen={modalOpen}
          closeFlag={closeFlag}
        />
      )}

      {modalOpen.currentComponent === "ADDTAX" && (
        <AddTax
          handleClose={handleClose}
          handelClick={handelClick}
          modalOpen={modalOpen}
          closeFlag={closeFlag}
        />
      )}

      {modalOpen.currentComponent === "USERVIEW" && (
        <ProviderDetails
          handleClose={handleClose}
          handelClick={handelClick}
          modalOpen={modalOpen}
          closeFlag={closeFlag}
        />
      )}
      {modalOpen.currentComponent === "OrderDownload" && (
        <OrderDownload
          handleClose={handleClose}
          handelClick={handelClick}
          modalOpen={modalOpen}
          closeFlag={closeFlag}
        />
      )}
      {modalOpen.currentComponent === "ExportUsers" && (
        <ExportUsers
          handleClose={handleClose}
          handelClick={handelClick}
          modalOpen={modalOpen}
          closeFlag={closeFlag}
        />
      )}
      {modalOpen.currentComponent === "OTPSTARTVERIFY" && (
        <OrderOtp
          handleClose={handleClose}
          handelClick={handelClick}
          modalOpen={modalOpen}
          closeFlag={closeFlag}
        />
      )}
      {modalOpen.currentComponent === "OTPENDVERIFY" && (
        <OrderEndOtpVerify
          handleClose={handleClose}
          handelClick={handelClick}
          modalOpen={modalOpen}
          closeFlag={closeFlag}
        />
      )}

       {modalOpen.currentComponent === "OTPSTARTSEND" && (
        <OrderStartOtpSend
          handleClose={handleClose}
          handelClick={handelClick}
          modalOpen={modalOpen}
          closeFlag={closeFlag}
        />
      )}

      {modalOpen.currentComponent === "OTPENDSEND" && (
        <OrderEndOtpSend
          handleClose={handleClose}
          handelClick={handelClick}
          modalOpen={modalOpen}
          closeFlag={closeFlag}
        />
      )}

      {modalOpen.currentComponent === "STATUSUPDATE" && (
        <StatusUpdate
          handleClose={handleClose}
          handelClick={handelClick}
          modalOpen={modalOpen}
          closeFlag={closeFlag}
        />
      )}

      {modalOpen.currentComponent === "OrderstatusChange" && (
        <StatusChange
          handleClose={handleClose}
          handelClick={handelClick}
          modalOpen={modalOpen}
          closeFlag={closeFlag}
        />
      )}

      {modalOpen.currentComponent === "ChangePassword" && (
        <ChangePassword
          handleClose={handleClose}
          handelClick={handelClick}
          modalOpen={modalOpen}
          closeFlag={closeFlag}
        />
      )}
    </Dialog>
  );
}
