import { get, post } from "../web.request";

export const login = async (data) => {
  return await post(`/adminLogin`, data);
};

export const providerLoginApiHandler = (data) => {
  return post(`/verifyLoginOtp`, data);
};

export const sendOtpApiHandler = (data) => {
  return post(`/sendLoginOtp`, data);
};
export const loadUser = async () => {
  return await post(`/token`);
};
