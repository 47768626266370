import { deleteRequest, get, post, put } from "../web.request";
export const getAllTaxApiHandler = (
  search,
  status,
  page,
  orderBy,
  flag
) => {
  return get(
    `taxList?search=${search}&status=${status}&orderBy=${orderBy}&sortFlag=${flag}&page=${page}`
  );
};

export const addTaxApiHandler = (payload) => {
  return post(`addTax`, payload);
};

export const EditTaxApiHandler = (id, payload) => {
  return put(`updateTax/${id}`, payload);
};

export const deleteTaxApiHandler = (id) => {
  return deleteRequest(`deleteTax/${id}`);
};

export const listTaxHandler = () => {
  return get(`taxDropdown`);
};

export const statusUpdateTaxHandler = (id) => {
  return put(`statusChangeTax/${id}`);
};
