import React, { useEffect, useState } from "react";
import {
  deleteServiceApiHandler,
  getAllServiceAllApiHandler,
  getVerifyAddress
} from "../../Service/Services.service";
import { deleteCategoryApiHandler } from "../../Service/category.service";
import {
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Button, Heading, Modal } from "../../components/Common";
import { Pagination, Search } from "../../components/Common/Table";
import { IoAdd } from "react-icons/io5";
import TableRowsLoader from "../../components/Common/Loader/Skeleton";
import NoRecordFound from "../../components/Common/Table/NoRecordFound";
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { VscFilter } from "react-icons/vsc";
import StatusFilter from "../../components/Common/Filter/StatusFilter";
import moment from "moment";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import {
  errorNotification,
  successNotification,
} from "../../components/Common/Notification";

function Services() {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
    isEdit: false,
    isView: false,
    id: "",
  });
  const [search, setSearch] = useState("");
  const [serviceList, setServiceList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [activeFlag, setActiveFlag] = useState("");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectCategory, setSelectCategory] = useState("");
  const [serviceFlag, setServiceFlag] = useState(false);
  const [priceFlag, setPriceFlag] = useState(false);
  const [cateNameFlag, setCateNameFlag] = useState(false);
  const [dateFlag, setDateFlag] = useState(false);
  const [orderBy, setOrderBy] = useState("");
  const [flag, setFlag] = useState("");
  const currRole = localStorage.getItem("ROLE");
  const statusUpdateHandler = (id) => {
    setIsModalOpen({
      open: true,
      currentComponent: "STATUSUPDATE",
      para: "Are you sure want to change status ?",
      head: "Chanege Status",
      id: id,
      name: "SERVICE",
    });
  };

  const resetHandler = () => {
    setSearch("");
    setPage(1);
    setActiveFlag("");
    setSelectCategory("");
    setOrderBy("");
    setFlag(false);
  };

  const dataHadler = (data) => {
    setActiveFlag(data.status);
    setSelectCategory(data.selectedCategory);
  };

  const sortingHandler = (name) => {
    switch (name) {
      case "createdAt":
        setOrderBy("createdAt");
        setDateFlag((current) => !current);
        setCateNameFlag(false);
        setFlag(dateFlag ? false : true);
        setPriceFlag(false);
        setServiceFlag(false);
        break;
      case "categoryname":
        setOrderBy("categoryname");
        setDateFlag(false);
        setCateNameFlag((current) => !current);
        setFlag(cateNameFlag ? false : true);
        setPriceFlag(false);
        setServiceFlag(false);
        break;
      case "cost":
        setOrderBy("cost");
        setDateFlag(false);
        setCateNameFlag(false);
        setPriceFlag((current) => !current);
        setServiceFlag(false);
        setFlag(priceFlag ? false : true);
        break;
      case "name":
        setOrderBy("name");
        setDateFlag(false);
        setCateNameFlag(false);
        setPriceFlag(false);
        setServiceFlag((current) => !current);
        setFlag(serviceFlag ? false : true);
        break;
      case "name":
        setFlag((current) => !current);
        setOrderBy("name");
        setDateFlag(false);
        setCateNameFlag(false);
        setPriceFlag(false);
        setServiceFlag((current) => !current);
        break;
      default:
        setFlag((current) => !current);
        setOrderBy("createdAt");
    }
  };

  const getAllServiceAllHandler = async (
    search,
    activeFlag,
    page,
    cateId,
    orderBy,
    flag
  ) => {
    try {
      setLoading(true);
      const { data, message, status, total } = await getAllServiceAllApiHandler(
        search,
        activeFlag,
        page,
        cateId,
        orderBy,
        flag
      );
      if (status) {
        setTotalData(total);
        setServiceList(data);
      } else {
        setTotalData(0);
        setServiceList([]);
      }
    } catch (err) {
      console.log("error", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllServiceAllHandler(
      search,
      activeFlag,
      page,
      selectCategory,
      orderBy,
      flag
    );
  }, [search, activeFlag, page, selectCategory, orderBy, flag]);

  const deleteServiceHandler = async (id) => {
    setIsModalOpen({
      open: true,
      currentComponent: "Delete",
      para: "Are you sure want to remove this service ?",
      head: "Remove Service",
      id: id,
      name: "Service",
    });
  };

  const pageChangeHandler = (data) => {
    setPage(data);
  };

  const addServiceHanlder = async () => {
    const { data, message, status, total } = await getVerifyAddress();
    if (status) {
      if (data.isAddress) {
        navigate(`/add-services`);
      } else {
        errorNotification(message)
      }
    }
  };

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const closeFlagHandler = () => {
    getAllServiceAllHandler("", "", 1, "", "", "");
  };

  return (
    <>
      <Stack mb={1}>
        <Heading head={"Service"} />
      </Stack>
      <Stack
        mb={1}
        gap={1}
        flexDirection={{ xs: 'column-reverse', sm: "row" }}
        justifyContent={"space-between"}
        alignItems={{ xs: 'end', sm: "center" }}
      >
        <Search search={search} setSearch={setSearch} setPage={setPage} />
        <Stack sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
          <Button
            buttonName="Service"
            startIcon={<IoAdd />}
            size="small"
            onClick={() => addServiceHanlder()}
          />
          <Button
            startIcon={<VscFilter />}
            onClick={() => toggleDrawer()}
            buttonName="Filter"
            color="white"
            size="small"
          />
          <StatusFilter
            openDrawer={openDrawer}
            toggleDrawer={toggleDrawer}
            resetHandler={resetHandler}
            dataHandler={dataHadler}
            CategoryFlag={true}
            activeStatus={true}
          />
        </Stack>
      </Stack>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Image</TableCell>
              <TableCell onClick={() => sortingHandler("name")}>
                Service Name
                {serviceFlag ? (
                  <FaArrowUp style={{ color: "#adadad" }} />
                ) : (
                  <FaArrowDown style={{ color: "#adadad" }} />
                )}
              </TableCell>
              <TableCell onClick={() => sortingHandler("cost")}>
                Price
                {priceFlag ? (
                  <FaArrowUp style={{ color: "#adadad" }} />
                ) : (
                  <FaArrowDown style={{ color: "#adadad" }} />
                )}
              </TableCell>
              <TableCell onClick={() => sortingHandler("categoryname")}>
                Category Name
                {cateNameFlag ? (
                  <FaArrowUp style={{ color: "#adadad" }} />
                ) : (
                  <FaArrowDown style={{ color: "#adadad" }} />
                )}
              </TableCell>
              {currRole == 1 ? (<TableCell>Provider Name</TableCell>) : ""}
              <TableCell onClick={() => sortingHandler("createdAt")}>
                Date
                {dateFlag ? (
                  <FaArrowUp style={{ color: "#adadad" }} />
                ) : (
                  <FaArrowDown style={{ color: "#adadad" }} />
                )}
              </TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRowsLoader rowsNum={8} />
            ) : serviceList && serviceList.length > 0 ? (
              serviceList.map((response, index) => {
                const {
                  createdAt,
                  categoryname,
                  cost,
                  firstname,
                  id,
                  imageData,
                  lastname,
                  name,
                  status,
                } = response || {};
                return (
                  <TableRow
                    key={index}
                    onClick={() =>
                      navigate(`/view-services`, {
                        state: {
                          id: id,
                          data: response,
                        },
                      })
                    }
                  >
                    <TableCell>
                      <img
                        src={imageData}
                        style={{ width: "50px", height: "50px", objectFit: 'cover' }}
                      />
                    </TableCell>
                    <TableCell>{name ? name : "-"}</TableCell>
                    <TableCell>{cost ? cost : "-"}</TableCell>
                    <TableCell>{categoryname ? categoryname : "-"}</TableCell>
                    {currRole == 1 ? (<TableCell>{`${firstname ? firstname : "-"} ${lastname ? lastname : ""
                      }`}</TableCell>) : ""}
                    <TableCell>
                      {moment(createdAt).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell>
                      <Switch
                        onClick={(event) => event.stopPropagation()}
                        checked={status === 1 ? true : false}
                        onChange={() => statusUpdateHandler(id)}
                        disabled={status === 2}
                        size='small'
                      />
                    </TableCell>
                    <TableCell onClick={(e) => e.stopPropagation()}>
                      <Stack
                        sx={{ flexDirection: "row", justifyContent: "center" }}
                        gap={1}
                      >
                        <MdOutlineEdit
                          style={{ fontSize: "20px", cursor: "pointer" }}
                          onClick={() =>
                            navigate(`/edit-services`, {
                              state: {
                                id: id,
                                data: response,
                              },
                            })
                          }
                        />
                        <MdDeleteOutline
                          onClick={() => deleteServiceHandler(id)}
                          style={{ fontSize: "20px", cursor: "pointer" }}
                        />
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <NoRecordFound rowsNum={8} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        pageNumber={page}
        count={totalData}
        pageChangeHandler={pageChangeHandler}
      />
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "", isEdit: "" })
        }
        closeFlag={closeFlagHandler}
      />
    </>
  );
}

export default Services;
