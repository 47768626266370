import { Typography } from '@mui/material'
import React from 'react'

const Heading = ({ head, smallHead }) => {
  return (
    <>
      {smallHead ? <Typography Typography fontSize={{ xs: '15px', sm: '18px' }} lineHeight={'26px'} fontWeight={'600'} mb={'5px'}>{smallHead}</Typography>
        : <Typography fontSize={{ xs: '20px', sm: '24px' }} lineHeight={'32px'} fontWeight={'600'}>{head}</Typography>
      }
    </>
  )
}

export default Heading