import React, { useEffect, useState } from "react";
import {
  getAllTaxApiHandler,
} from "../../Service/tax.service";
import {
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Heading from "../../components/Common/Heading";
import { Pagination, Search } from "../../components/Common/Table";
import TableRowsLoader from "../../components/Common/Loader/Skeleton";
import NoRecordFound from "../../components/Common/Table/NoRecordFound";
import { Button, Modal } from "../../components/Common";
import { IoAdd } from "react-icons/io5";
import { MdOutlineEdit } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import { VscFilter } from "react-icons/vsc";
import StatusFilter from "../../components/Common/Filter/StatusFilter";
import moment from "moment";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";

function Tax() {
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
    isEdit: false,
    isView: false,
    id: "",
  });
  const [search, setSearch] = useState("");
  const [taxList, setTaxList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [activeFlag, setActiveFlag] = useState("");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [taxFlag, setTaxFlag] = useState(false);
  const [dateFlag, setDateFlag] = useState(false);
  const [orderBy, setOrderBy] = useState("");
  const [flag, setFlag] = useState("");

  const getCategoreyListHandler = async (
    search,
    activeFlag,
    page,
    orderBy,
    flag
  ) => {
    try {
      setLoading(true);
      const { data, message, status, total } = await getAllTaxApiHandler(
        search,
        activeFlag,
        page,
        orderBy,
        flag
      );
      if (status) {
        setTaxList(data);
        setTotalData(total);
      } else {
        setTotalData(0);
        setTaxList([]);
      }
    } catch (e) {
      console.log("error", e);
    } finally {
      setLoading(false);
    }
  };

  const closeFlagHandler = () => {
    getCategoreyListHandler("", "", 1, "", "");
  };

  const pageChangeHandler = (page) => {
    setPage(page);
    getCategoreyListHandler(search, activeFlag, page, orderBy, flag);
  };

  const resetHandler = () => {
    setSearch("");
    setPage(1);
    setActiveFlag("");
    setDateFlag(false);
    setOrderBy("");
    setFlag("");
  };

  const dataHadler = (data) => {
    setActiveFlag(data.status);
  };

  const sortingHandler = (name) => {
    switch (name) {
      case "createdAt":
        setDateFlag((current) => !current);
        setFlag(dateFlag ? false : true);
        setTaxFlag(false);
        setOrderBy("createdAt");
        break;
      case "name":
        setTaxFlag((current) => !current);
        setDateFlag(false);
        setOrderBy("name");
        setFlag(taxFlag ? false : true);
        break;
      default:
        setFlag((current) => !current);
        setOrderBy("createdAt");
    }
  };

  useEffect(() => {
    getCategoreyListHandler(search, activeFlag, page, orderBy, flag);
  }, [search, activeFlag, page, activeFlag, orderBy, flag]);

  const deleteTaxHandler = async (id) => {
    setIsModalOpen({
      open: true,
      currentComponent: "Delete",
      para: "Are you sure want to remove this tax ?",
      head: "Remove Tax",
      id: id,
      name: "Tax",
    });
    // try {
    //   setLoading(true);
    //   const { data, message, status } = await deleteTaxApiHandler(id);
    //   if (status) {
    //     getCategoreyListHandler(search, activeFlag, page, orderBy, flag);
    //   } else {
    //   }
    // } catch (e) {
    //   console.log("error", e);
    // } finally {
    //   setLoading(false);
    // }
  };

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const statusUpdateHandler = (id) => {
    // STATUSUPDATE
    setIsModalOpen({
      open: true,
      currentComponent: "STATUSUPDATE",
      para: "Are you sure want to change status ?",
      head: "Chanege Status",
      id: id,
      name: "TAX",
    });
  };

  return (
    <>
      <Stack
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        mb={1}
      >
        <Heading head="Tax" />
      </Stack>
      <Stack
        mb={1} gap={1}
        flexDirection={{ xs: 'column-reverse', sm: "row" }}
        justifyContent={"space-between"}
        alignItems={{ xs: 'end', sm: "center" }}
      >
        <Search search={search} setSearch={setSearch} setPage={setPage} />
        <Stack sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
          <Button
            buttonName="Tax"
            startIcon={<IoAdd />}
            size="small"
            onClick={() =>
              setIsModalOpen({
                open: true,
                currentComponent: "ADDTAX",
                componentStatus: "ADD",
              })
            }
          ></Button>
          <Button
            startIcon={<VscFilter />}
            onClick={() => toggleDrawer()}
            buttonName="Filter"
            color="white"
            size="small"
          />
          <StatusFilter
            openDrawer={openDrawer}
            toggleDrawer={toggleDrawer}
            resetHandler={resetHandler}
            dataHandler={dataHadler}
            activeStatus={true}
          />
        </Stack>
      </Stack>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Label</TableCell>
              <TableCell>Tax Value</TableCell>
              <TableCell onClick={() => sortingHandler("createdAt")}>
                Date
                {dateFlag ? (
                  <FaArrowUp style={{ color: "#adadad" }} />
                ) : (
                  <FaArrowDown style={{ color: "#adadad" }} />
                )}
              </TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRowsLoader rowsNum={5} />
            ) : taxList && taxList.length > 0 ? (
              taxList.map((response, index) => {
                const { id, lable, name, taxValue, status, createdAt } = response;
                return (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                  >
                    <TableCell>{name ? name : "-"}</TableCell>
                    <TableCell>{lable ? lable : "-"}</TableCell>
                    <TableCell>{taxValue ? taxValue : "-"}</TableCell>
                    <TableCell>
                      {moment(createdAt).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell>
                      <Switch
                        onClick={(e) => e.stopPropagation()}
                        checked={status === 1 ? true : false}
                        onChange={() => statusUpdateHandler(id)}
                        disabled={status === 2}
                        size="small"
                      />
                    </TableCell>
                    <TableCell onClick={(e) => e.stopPropagation()}>
                      <Stack
                        sx={{ flexDirection: "row", justifyContent: "center" }}
                        gap={1}
                      >
                        <MdOutlineEdit
                          style={{ fontSize: "20px", cursor: "pointer" }}
                          onClick={() =>
                            setIsModalOpen({
                              open: true,
                              currentComponent: "ADDTAX",
                              componentStatus: "EDIT",
                              data: {
                                response,
                                id,
                              },
                            })
                          }
                        />
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <NoRecordFound rowsNum={5} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        pageNumber={page}
        count={totalData}
        pageChangeHandler={pageChangeHandler}
      />
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "", isEdit: "" })
        }
        closeFlag={closeFlagHandler}
      />
    </>
  );
}

export default Tax;
