import { get,put } from "../web.request";

export const getOrderListApiHandler = (
  providerId,
  serviceId,
  time,
  date,
  search,
  status,
  orderBy,
  flag,
  page
) => {
  const role = parseInt(localStorage.getItem("ROLE")); 
  const userId = localStorage.getItem("USERID");

  if (!isNaN(role) && role === 1) {
    return get(
      `bookingList?search=${search}&providerId=${providerId}&serviceId=${serviceId}&time=${time}&date=${date}&status=${status}&orderBy=${orderBy}&sortFlag=${flag}&page=${page}`
    );
  } else if (userId) { 
    return get(
      `bookingList?search=${search}&providerId=${userId}&serviceId=${serviceId}&time=${time}&date=${date}&status=${status}&orderBy=${orderBy}&sortFlag=${flag}&page=${page}`
    );
  } else {
    console.error("Invalid role or userId is missing.");
  }
 
};

export const OrderStatusChange = async (id, data) => {
  return await put(`/statusChangeBooking/${id}`, data);
}

export const OrderDetail = async (id) => {
  return await get(`/bookingDetail/${id}`);
}
export const serviceDropdownHandler = () => {
  return get(`serviceDropdown`);
};

export const timeDropdownHandler = () => {
  return get(`timeDropdown`);
};

export const VerifyBookingOtp = async (id, data) => {
  return await put(`/verifyServiceStartOtp/${id}`, data);
}

export const VerifyServiceEndOtp = async (id, data) => {
  return await put(`/verifyServiceEndOtp/${id}`, data);
}
export const SendServiceStartOtp = async (id) => {
  return await put(`/sendServiceStartOtp/${id}`);
}

export const SendServiceEndOtp = async (id) => {
  return await put(`/sendServiceEndOtp/${id}`);
}