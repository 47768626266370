import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CloseIcon } from "../../helper/Icons";
import { Button, FileUpload, Input } from "../../components/Common";
import { useFormik } from "formik";
import { taxIv } from "../../helper/initialValues";
import { taxValidation } from "../../helper/validation";
import {
  EditTaxApiHandler,
  addTaxApiHandler,
} from "../../Service/tax.service";
import { FaTrashCan } from "react-icons/fa6";
import { IoCamera } from "react-icons/io5";
import {
  errorNotification,
  successNotification,
} from "../../components/Common/Notification";

function AddTax({ handleClose, modalOpen, closeFlag }) {
  console.log("ModalOpen", modalOpen);
  const componentStatus = modalOpen.componentStatus;
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [deleteImage, setDeleteImage] = useState([]);
  const isEdit = modalOpen?.isEdit;
  const isView = modalOpen?.isView;

  const taxHandler = async () => {
    try {
      setLoading(true);

      const payload = {
        name: formik.values.name,
        lable: formik.values.lable,
        taxValue: formik.values.taxValue,
        description: formik.values.description,
      }

      const { data, message, status } =
        componentStatus === "ADD"
          ? await addTaxApiHandler(payload)
          : await EditTaxApiHandler(modalOpen?.data?.id, payload);

      if (status) {
        closeFlag();
        handleClose();
        successNotification(message);
      } else {
        errorNotification(message);
        handleClose();
        closeFlag();
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (componentStatus === "EDIT") {
      getDataHandler(modalOpen?.data);
    }
  }, []);

  const getDataHandler = (data) => {
    formik.setFieldValue("name", data?.response?.name);
    formik.setFieldValue("lable", data?.response?.lable);
    formik.setFieldValue("taxValue", data?.response?.taxValue);
    formik.setFieldValue("description", data?.response?.description);
  };

  const formik = useFormik({
    initialValues: taxIv,
    validationSchema: taxValidation,
    onSubmit: taxHandler,
  });



  const handleRemoveImage = () => {
    setImage(null);
    setFiles("");
    formik.setFieldValue("image", "");
  };

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {componentStatus === "ADD" ? "Add" : " Edit"} Tax
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ width: { sm: "500px" } }}>
        <Grid container gap={2} paddingY={1}>
          <Grid item xs={12}>
            <Input
              labelinput="Name"
              formik={formik}
              name="name"
              maxLength={50}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              labelinput="Lable"
              formik={formik}
              name="lable"
              maxLength={50}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              labelinput="Tax Value"
              formik={formik}
              name="taxValue"
              maxLength={50}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              labelinput="description"
              formik={formik}
              name="description"
              maxLength={50}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          buttonName="Cancel"
          size="small"
          color="white"
          onClick={handleClose}
        />
        <Button
          buttonName={"Save"}
          size="small"
          loading={loading}
          disabled={loading}
          onClick={formik.handleSubmit}
        />
      </DialogActions>
    </>
  );
}

export default AddTax;
