import {
  Divider,
  Drawer,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
// import DatePicker from '@mui/lab/DatePicker';
import React, { useEffect } from "react";
import { CloseIcon } from "../../../helper/Icons";
import { statusFilter, AppoinmentstatusFilter } from "../../../helper/Helper";
import { Button, Input, DateRange } from "..";
import { listCategoryHandler } from "../../../Service/category.service";
import {
  serviceDropdownHandler,
  timeDropdownHandler,
} from "../../../Service/Orders.service";

function StatusFilter({
  toggleDrawer,
  openDrawer,
  dataHandler,
  DateRageFlag,
  activeStatus,
  resetHandler,
  CategoryFlag,
  TimeFlag,
  DateFlag,
  StartDateFlag,
  EndDateFlag,
  ServiceFlag,
  OrderStatusFlag,
}) {
  const drawerWidth = 250;
  const [status, setStatus] = React.useState("");
  const [orderStatus, setOrderStatus] = React.useState("");
  const [categoryData, setCategoryData] = React.useState([]);
  const [selectedCategory, setSelectCategory] = React.useState("");
  const [serviceData, setServiceData] = React.useState([]);
  const [selectedService, setSelectService] = React.useState("");
  const [timeData, setTimeData] = React.useState([]);
  const [selectedTime, setSelectTime] = React.useState("");
  const [selectedDate, setSelectDate] = React.useState("");
  const [selectedStartDate, setSelectStartDate] = React.useState("");
  const [selectedEndDate, setSelectEndDate] = React.useState("");


  const applyHandler = () => {
    toggleDrawer();
    dataHandler({
      status: status,
      selectedCategory: selectedCategory,
      selectedService: selectedService,
      orderStatus: orderStatus,
      selectedTime: selectedTime,
      selectedDate: selectedDate,
      selectedEndDate: selectedEndDate,
      selectedStartDate: selectedStartDate,
    });
  };

  const anyFieldIsNotNull = () => {

    return (
      status === "" &&
      selectedCategory === "" &&
      selectedService === "" &&
      orderStatus === "" &&
      selectedTime === "" &&
      selectedDate === "" &&
      selectedStartDate === "" &&
      selectedEndDate === "" &&
      selectedCategory === ""
    );
  };


  const resetButtonHandler = () => {
    setStatus("");
    resetHandler();
    toggleDrawer();
    setOrderStatus("");
    setSelectTime("");
    setSelectService("");
    setSelectDate("");
    setSelectStartDate("");
    setSelectEndDate("");
    setSelectCategory("");
  };

  useEffect(() => {
    if (CategoryFlag) {
      getCategoryListHandler();
    }
    if (ServiceFlag) {
      getServiceListHandler();
    }
    if (TimeFlag) {
      getTimeListHandler();
    }
  }, []);

  const getCategoryListHandler = async () => {
    try {
      const { data, message, status, total } = await listCategoryHandler();
      if (status) {
        setCategoryData(data);
      } else {
        setCategoryData([]);
      }
    } catch (e) {
      console.log(e);
    } finally {
    }
  };

  const getTimeListHandler = async () => {
    try {
      const { data, message, status, total } = await timeDropdownHandler();
      if (status) {
        setTimeData(data);
      } else {
        setTimeData([]);
      }
    } catch (e) {
      console.log(e);
    } finally {
    }
  };

  const getServiceListHandler = async () => {
    try {
      const { data, message, status, total } = await serviceDropdownHandler();
      if (status) {
        setServiceData(data);
      } else {
        setServiceData([]);
      }
    } catch (e) {
      console.log(e);
    } finally {
    }
  };
  const toggleDrawerHandler = () => { };

  return (
    <>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={toggleDrawer}
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            padding: 2,
          },
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography fontWeight={600} fontSize={"20px"}>
              Filter
            </Typography>
            <IconButton
              aria-label="close"
              onClick={toggleDrawer}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <Grid item xs={12} marginY={"5px"}>
              <Divider sx={{ borderColor: "gray.main" }} />
            </Grid>
          </Grid>

          {CategoryFlag && (
            <Grid item xs={12} marginTop={2}>
              <Typography
                fontSize={"14px"}
                fontWeight={600}
                marginBottom={"6px"}
              >
                {"Category"}
              </Typography>
              <Select
                displayEmpty
                fullWidth
                value={selectedCategory}
                onChange={(event) => setSelectCategory(event.target.value)}
              >
                {categoryData && categoryData.length > 0 ? (
                  categoryData.map((response, index) => {
                    return (
                      <MenuItem value={response.id}>{response.name}</MenuItem>
                    );
                  })
                ) : (
                  <></>
                )}
              </Select>
            </Grid>
          )}
          {ServiceFlag && (
            <Grid item xs={12} marginTop={2}>
              <Typography
                fontSize={"14px"}
                fontWeight={600}
                marginBottom={"6px"}
              >
                {"Service"}
              </Typography>
              <Select
                displayEmpty
                fullWidth
                value={selectedService}
                onChange={(event) => setSelectService(event.target.value)}
              >
                {serviceData && serviceData.length > 0 ? (
                  serviceData.map((response, index) => {
                    return (
                      <MenuItem value={response.id}>{response.name}</MenuItem>
                    );
                  })
                ) : (
                  <></>
                )}
              </Select>
            </Grid>
          )}

          {DateFlag && (
            <Grid item xs={12} marginTop={2}>
              <Grid item xs={12}>
                <Input labelinput='Date' type='date' name='date' isDate={true}
                  value={selectedDate}
                  onChange={(event) => setSelectDate(event.target.value)}
                />
              </Grid>
            </Grid>
          )}

          {StartDateFlag && (
            <Grid item xs={12} marginTop={2}>
              <Grid item xs={12}>
                <Input labelinput='Start Date' type='date' name='date' isDate={true}
                  value={selectedStartDate}
                  onChange={(event) => setSelectStartDate(event.target.value)}
                />
              </Grid>
            </Grid>
          )}

          {EndDateFlag && (
            <Grid item xs={12} marginTop={2}>
              <Grid item xs={12}>
                <Input labelinput='End Date' type='date' name='date' isDate={true}
                  value={selectedEndDate}
                  onChange={(event) => setSelectEndDate(event.target.value)}
                />
              </Grid>
            </Grid>
          )}

          {DateRageFlag && (
            <Grid item xs={12} marginTop={2}>
              <Grid item xs={12}>
                <Typography
                  fontSize={"14px"}
                  fontWeight={600}
                  marginBottom={"6px"}
                >
                  {"Select Date"}
                </Typography>
                <DateRange />
              </Grid>
            </Grid>
          )}

          {TimeFlag && (
            <Grid item xs={12} marginTop={2}>
              <Typography
                fontSize={"14px"}
                fontWeight={600}
                marginBottom={"6px"}
              >
                {"Time"}
              </Typography>
              <Select
                displayEmpty
                fullWidth
                value={selectedTime}
                onChange={(event) => setSelectTime(event.target.value)}
              >
                {timeData && timeData.length > 0 ? (
                  timeData.map((response, index) => {
                    return (
                      <MenuItem value={response.id}>{response.name}</MenuItem>
                    );
                  })
                ) : (
                  <></>
                )}
              </Select>
            </Grid>
          )}
          {OrderStatusFlag && (
            <Grid item xs={12} marginTop={2}>
              <Typography
                fontSize={"14px"}
                fontWeight={600}
                marginBottom={"6px"}
              >
                {"Order Status"}
              </Typography>
              <Select
                fullWidth
                displayEmpty
                value={orderStatus}
                onChange={(event) => setOrderStatus(event.target.value)}
              >
                {AppoinmentstatusFilter &&
                  AppoinmentstatusFilter.map((response, index) => {
                    return (
                      <MenuItem value={response.id}>{response.name}</MenuItem>
                    );
                  })}
              </Select>
            </Grid>
          )}

          {activeStatus && (
            <Grid item xs={12} marginTop={2}>
              <Typography
                fontSize={"14px"}
                fontWeight={600}
                marginBottom={"6px"}
              >
                {"Status"}
              </Typography>
              <Select
                fullWidth
                displayEmpty
                value={status}
                onChange={(event) => setStatus(event.target.value)}
              >
                {statusFilter &&
                  statusFilter.map((response, index) => {
                    return (
                      <MenuItem value={response.id}>{response.name}</MenuItem>
                    );
                  })}
              </Select>
            </Grid>
          )}

          <Stack flexDirection={"row"} marginTop={2} gap={3}>
            <Button
              buttonName="Reset"
              color="white"
              onClick={() => resetButtonHandler()}

            />
            <Button
              buttonName="Apply"
              disabled={anyFieldIsNotNull()}
              onClick={() => applyHandler()}
            />

          </Stack>
        </Grid>
      </Drawer>
    </>
  );
}

export default StatusFilter;
