import React from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router';

const BackButton = () => {
  const navigate = useNavigate();
  return (
    <>
      <ArrowBackIcon
        onClick={() => navigate(-1)}
        style={{ cursor: "pointer", background: "#FFCC00", color: "#000", borderRadius: '5px', padding: '4px', fontSize: '28px' }}
      />
    </>
  )
}

export default BackButton