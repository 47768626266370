import {
    Grid, Input, IconButton, DialogTitle,
    Avatar,
    Typography,
    DialogContent,
    Stack

} from '@mui/material';
import { CloseIcon } from "../../helper/Icons";
import React, { useEffect, useState } from 'react';
import { OrderDetail } from '../../Service/Orders.service';
import userDummy from '../../assets/images/user.jpg';
import { useLocation } from 'react-router-dom';
import { getUserDetailApiHandler } from '../../Service/Users.service';

const ProviderDetails = ({ handleClose, modalOpen, closeFlag }) => {
    const componentStatus = modalOpen.componentStatus;

    const { state } = useLocation();
    const [user, setUser] = useState({});
    console.log(user, modalOpen?.id);

    useEffect(() => {
        fetchUserData();
    }, [state]);

    const fetchUserData = async () => {
        const res = await getUserDetailApiHandler(modalOpen?.id);
        if (res?.status) {
            setUser(res.data);
        } else {
            setUser({});
        }
    };


    return (
        <>
            <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
                User Details
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{ width: { sm: "400px" } }}>
                <Stack gap={{ xs: 2, sm: 2 }}>
                    <Stack>
                        <Avatar
                            src={user?.profile ? user?.profile : userDummy}
                            sx={{ width: 100, height: 100, margin: 'auto' }}
                        />
                        <Typography fontWeight={'500'} fontSize={{ xs: '18px', sm: '20px' }} sx={{ textAlign: 'center', mt: 1 }}>{user?.firstName} {user?.lastName}</Typography>
                    </Stack>
                    <Stack flexDirection={{ sm: 'row' }} gap={{ xs: 1, sm: 2 }}>
                        <Stack width={{ sm: '50%' }} >
                            <Typography fontWeight={'500'} fontSize={{ sm: '18px' }} color={'#999'}>Email Address</Typography>
                            <Typography fontWeight={'500'} fontSize={{ sm: '18px' }} width={"fit-content"}>{user?.email}</Typography>
                        </Stack>
                        <Stack width={{ sm: '50%' }}>
                            <Typography fontWeight={'500'} fontSize={{ sm: '18px' }} color={'#999'}>Phone No.</Typography>
                            <Typography fontWeight={'500'} fontSize={{ sm: '18px' }}>{user?.mobileNo}</Typography>
                        </Stack>
                    </Stack>
                    <Stack>
                        <Typography fontWeight={'500'} fontSize={{ sm: '18px' }} color={'#999'}>Address</Typography>
                        <Stack>
                            {user?.addressData?.map((item, i) => {
                                const { name, address, city, state, pincode } = item;
                                return (
                                    <Typography key={i} fontWeight={'500'} fontSize={{ sm: '18px' }}>{name}, {address}, {city}, {state}, {pincode}</Typography>
                                )
                            })}
                        </Stack>
                    </Stack>
                </Stack>
            </DialogContent>
        </>
    );
}

export default ProviderDetails