import { Box, Grid, Stack, Typography, Divider, Select, MenuItem, FormHelperText } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BackButton, Button, Heading, Input } from "../../components/Common";
import { FaRegUser } from "react-icons/fa";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { FaTrashCan } from "react-icons/fa6";
import { IoCamera } from "react-icons/io5";
import {
    getProviderProfile,
    servicePProfileUpdateApiHandler,
    getPincodeDetails,
    handleBankDetails
} from "../../Service/Users.service";
import { useFormik } from "formik";
import { ProviderProfileValidation, BankDetailsSPValidation } from "../../helper/validation";
import { providerProfileIv, bankDetailsIv } from "../../helper/initialValues";
import {
    errorNotification,
    successNotification,
} from "../../components/Common/Notification";


function Profileprovider() {
    const [image, setImage] = useState(null);
    const [userDetails, setUserDetails] = useState({});
    const [loading, setLoading] = useState(false);
    const [loadingbank, setLoadingbank] = useState(false);
    const [files, setFiles] = useState([]);
    const [edit, setEdit] = useState();
    const [bankEdit, setBankEdit] = useState();
    const handleClick = () => setEdit((show) => !show);
    const handleClickBank = () => setBankEdit((show) => !show);
    const userId = localStorage.getItem("USERID");

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFiles(file);
            formik.setFieldValue("profile", file);
            // formik.setFieldValue("image", file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const getUserDetails = async (userId) => {
        try {
            setLoading(true);
            const { data, message, status, total } = await getProviderProfile(
                userId
            );
            if (status) {
                setUserDetails(data);
                const userData = data;
                const BankData = data.bankDetails;
                formik.setValues({
                    ...formik.values,
                    firstName: userData?.firstName ?? "",
                    lastName: userData?.lastName ?? "",
                    email: userData?.email ?? "",
                    pan: userData?.panNo ?? "",
                    GSTNo: userData?.GSTNo ?? "",
                    aadhar: userData?.aadharNo ?? "",
                    mobile: userData?.mobileNo ?? "",
                    profile: userData?.profile ?? "",
                    address: userData?.addressData?.address ?? "",
                    landmark: userData?.addressData?.landmark ?? "",
                    pincode: userData?.addressData?.pincode ?? "",
                    state: userData?.addressData?.state ?? "",
                    city: userData?.addressData?.city ?? "",
                });
                formikBank.setValues({
                    ...formikBank.values,
                    acname: BankData?.acname,
                    acno: BankData?.acno,
                    reacno: BankData?.acno,
                    isfccode: BankData?.isfccode,
                    actype: BankData?.actype
                })
                setImage(userData?.profile);
                setEdit(false)
            } else {
                errorNotification(message);
            }
        } catch (e) {
            console.log(e);
            errorNotification("Something goes wrong");
        } finally {
            setLoading(false);
        }
    };

    const profileUpdateHandler = async () => {
        try {
            setLoading(true);
            const formData = new FormData();
            formData.append("firstName", formik.values.firstName);
            formData.append("lastName", formik.values.lastName);
            formData.append("email", formik.values.email);
            formData.append("mobile", formik.values.mobile);
            formData.append("aadharNo", formik.values.aadhar);
            formData.append("panNo", formik.values.pan);
            formData.append("GSTNo", formik.values.GSTNo);
            formData.append("profile", files);
            formData.append("address", formik.values.address);
            formData.append("landmark", formik.values.landmark);
            formData.append("pincode", formik.values.pincode);
            formData.append("state", formik.values.state);
            formData.append("city", formik.values.city);

            const { data, message, status } = await servicePProfileUpdateApiHandler(
                userDetails.id,
                formData
            );
            if (status) {
                getUserDetails(userId);
                successNotification(message);
            } else {
                errorNotification(message);
            }
        } catch (err) {
            console.log("error", err);
        } finally {
            setLoading(false);
        }
    };

    const formik = useFormik({
        initialValues: providerProfileIv,
        validationSchema: ProviderProfileValidation,
        onSubmit: profileUpdateHandler,
    });

    const handleSubmitBankDetails = async () => {
        try {
            console.log('Bank details Data', formikBank.values);
            const { data, message, status } = await handleBankDetails(formikBank.values);
            if (status) {
                successNotification(message);
                handleClickBank();
            } else {
                errorNotification(message);
            }
        } catch (error) {
            setLoading(false);
            errorNotification("Someting goes wrong")
        } finally {
            setLoading(false);
        }
    }

    const formikBank = useFormik({
        initialValues: bankDetailsIv,
        validationSchema: BankDetailsSPValidation,
        onSubmit: handleSubmitBankDetails,
    });

    const checkPincode = async (e) => {
        const pc = e.target.value;
        formik.setFieldValue("pincode", pc)
        if (e.target.value?.length == 6) {
            const getRes = await getPincodeDetails(pc);
            if (getRes.data.status == "OK") {
                console.log('-------------------', getRes?.data);
                const addressComponents = getRes?.data?.results[0]?.address_components;
                const cityComponent = addressComponents.find(component =>
                    component.types.includes('locality')
                );
                const stateComponent = addressComponents.find(component =>
                    component.types.includes('administrative_area_level_1')
                );
                formik.setFieldValue("city", cityComponent ? cityComponent.long_name : '')
                formik.setFieldValue("state", stateComponent ? stateComponent.long_name : '')

            } else {
                formik.setFieldValue("pincode", "")
                formik.setFieldValue("city", '')
                formik.setFieldValue("state", '')
                errorNotification("Please enter valid pincode");
            }

        }
    }

    const taxSelectHandler = (value) => {
        // setSelectTax(value);
        formik.setFieldValue("actype", value);
    };

    const handleAccountType = (event) => {
        taxSelectHandler(event.target.value)
        formikBank.setFieldValue("actype", event.target.value)
    }


    useEffect(() => {
        getUserDetails(userId);
    }, []);

    const handleRemoveImage = () => {
        setImage(null);
        setFiles("");
        formik.setFieldValue("profile", "");
        // formik.setFieldValue("image", "");
    };
    return (
        <>
            <Stack justifyContent={"flex-start"}>
                <Stack flexDirection={"row"} gap={1} alignItems={"center"} mb={1}>
                    <BackButton />
                    <Heading head={"Profile"} />
                </Stack>
                <Grid container spacing={2} >
                    <Grid item xs={12} md={6} >
                        <Box sx={{ border: "1px solid #eee", borderRadius: "16px", backgroundColor: 'white.main' }} p={{ xs: 3, sm: 4 }}>
                            <Grid container spacing={{ xs: 2, sm: 3 }}>
                                <Grid item xs={12}>
                                    <Grid container sx={{ width: { xs: "100%", sm: "100%" } }}>
                                        <Grid
                                            item
                                            xs={12}
                                            display={"flex"}
                                            justifyContent={"center"}
                                        >
                                            <Box
                                                sx={{
                                                    position: "relative",
                                                    border: "1px solid rgba(0, 0, 0, 0.12)",
                                                    borderRadius: "50%",
                                                    width: "100px",
                                                    height: "100px",
                                                    overflow: "hidden",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {image ? (
                                                    <div
                                                        className="cv_uploaded_image"
                                                        style={{
                                                            position: "relative",
                                                            width: "100%",
                                                            height: "100%",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <div className="cv_uploaded_image_trash">
                                                            <FaTrashCan
                                                                style={{ position: "absolute", color: "#fff" }}
                                                                onClick={handleRemoveImage}
                                                            />
                                                        </div>
                                                        <img
                                                            src={image}
                                                            alt="Selected Image"
                                                            style={{
                                                                maxWidth: "100%",
                                                                maxHeight: "100%",
                                                                zIndex: 1,
                                                                objectFit: "cover",
                                                            }}
                                                        />
                                                    </div>
                                                ) : (
                                                    <div
                                                        style={{
                                                            position: "relative",
                                                            width: "100%",
                                                            height: "100%",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <FaRegUser
                                                            style={{
                                                                fontSize: "50px",
                                                                color: "rgba(0, 0, 0, 0.12)",
                                                            }}
                                                        />
                                                        <input
                                                            type="file"
                                                            accept="image/*"
                                                            onChange={handleFileChange}
                                                            style={{
                                                                position: "absolute",
                                                                width: "100%",
                                                                height: "100%",
                                                                cursor: "pointer",
                                                                opacity: "0",
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            </Box>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            display={"flex"}
                                            justifyContent={"center"}
                                            marginTop={1}
                                        >
                                            <Typography fontSize={"18px"} fontWeight={500}>
                                                {userDetails.firstName ? userDetails.firstName : "-"}{" "}
                                                {userDetails.lastName ? userDetails.lastName : "-"}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Input
                                        labelinput="First Name"
                                        name="firstName"
                                        formik={formik}
                                        disabled={edit ? false : true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Input
                                        labelinput="Last Name"
                                        name="lastName"
                                        formik={formik}
                                        disabled={edit ? false : true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Input
                                        labelinput="Email Address"
                                        name="email"
                                        formik={formik}
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Input
                                        labelinput="Mobile Number"
                                        name="mobile"
                                        formik={formik}
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Input
                                        labelinput="Aadhar Number"
                                        name="aadhar"
                                        formik={formik}
                                        disabled={true}
                                        maxLength={12}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Input
                                        labelinput="Pan Number"
                                        name="pan"
                                        formik={formik}
                                        disabled={edit ? false : true}
                                        maxLength={10}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Input
                                        labelinput="GST Number"
                                        name="GSTNo"
                                        formik={formik}
                                        disabled={false}
                                        maxLength={20}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Heading head={"Address"} />
                                    <Divider sx={{ my: { xs: 2, sm: 3 } }} />
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    <Input
                                        labelinput="Address"
                                        name="address"
                                        formik={formik}
                                        disabled={edit ? false : true}
                                        maxLength={200}
                                        multiline={true}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Input
                                        labelinput="Landmark"
                                        name="landmark"
                                        formik={formik}
                                        disabled={edit ? false : true}
                                        maxLength={200}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Input
                                        labelinput="Pincode"
                                        name="pincode"
                                        formik={formik}
                                        disabled={edit ? false : true}
                                        maxLength={6}
                                        onChange={checkPincode}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Input
                                        labelinput="State"
                                        name="state"
                                        formik={formik}
                                        disabled={edit ? false : true}
                                        maxLength={150}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Input
                                        labelinput="City"
                                        name="city"
                                        formik={formik}
                                        disabled={edit ? false : true}
                                        maxLength={150}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Stack
                                        gap={1}
                                        flexDirection={"row"}
                                        justifyContent={"flex-end"}
                                    >
                                        {edit ? (
                                            <>
                                                <Button
                                                    buttonName="Cancel"
                                                    disabled={loading}
                                                    onClick={handleClick}
                                                />
                                                <Button
                                                    buttonName="Save"
                                                    disabled={loading}
                                                    onClick={() => profileUpdateHandler()}
                                                />
                                            </>
                                        ) : (
                                            <Button
                                                buttonName="Edit"
                                                disabled={loading}
                                                onClick={handleClick}
                                            />
                                        )}
                                    </Stack>

                                </Grid>


                            </Grid>

                        </Box>
                    </Grid>

                    <Grid item xs={12} md={6} >
                        <Box sx={{ border: "1px solid #eee", borderRadius: "16px", backgroundColor: 'white.main' }} p={{ xs: 3, sm: 4 }}>
                            <Grid container spacing={{ xs: 2, sm: 3 }}>
                                <Grid item xs={12}>
                                    <Heading head={"Bank Details"} />
                                    <Divider sx={{ my: { xs: 2, sm: 3 } }} />
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    <Input
                                        labelinput="Account Holder Name"
                                        name="acname"
                                        formik={formikBank}
                                        disabled={bankEdit ? false : true}
                                        maxLength={255}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    <Input
                                        labelinput="Account Number"
                                        name="acno"
                                        formik={formikBank}
                                        disabled={bankEdit ? false : true}
                                        maxLength={20}
                                        type={bankEdit ? "password" : "text"}
                                        autoComplete="new-password"
                                    />
                                </Grid>
                                {bankEdit ? (<Grid item xs={12} sm={12}>
                                    <Input
                                        labelinput="Re-Account Number"
                                        name="reacno"
                                        formik={formikBank}
                                        disabled={bankEdit ? false : true}
                                        maxLength={20}
                                    />
                                </Grid>) : ""}


                                <Grid item xs={12} sm={6}>
                                    <Input
                                        labelinput="IFSC Code"
                                        name="isfccode"
                                        formik={formikBank}
                                        disabled={bankEdit ? false : true}
                                        maxLength={15}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Typography fontSize={{ xs: "13px", sm: "16px" }}
                                        fontWeight={500}
                                        mb={"2px"}>
                                        Account Type
                                    </Typography>
                                    <Select
                                        displayEmpty
                                        // disabled={Componentstatus === "VIEW" ? currRole == 2 ? false : true : false}
                                        disabled={bankEdit ? false : true}
                                        value={formikBank && formikBank.values.actype || ""}
                                        name="actype"
                                        error={formik.touched["actype"] && formik.errors["actype"]}
                                        onChange={handleAccountType}
                                        fullWidth
                                    >
                                        <MenuItem value={"saving"}>
                                            Saving
                                        </MenuItem>
                                        <MenuItem value={"current"}>
                                            Current
                                        </MenuItem>
                                    </Select>
                                    {formik.touched["tax"] && (
                                        <FormHelperText sx={{ color: "red !important" }}>
                                            {formik.errors.tax}
                                        </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider sx={{ my: { xs: 2, sm: 3 } }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack
                                        gap={1}
                                        flexDirection={"row"}
                                        justifyContent={"flex-end"}
                                    >
                                        {bankEdit ? (
                                            <>
                                                <Button
                                                    buttonName="Cancel"
                                                    disabled={loadingbank}
                                                    onClick={handleClickBank}
                                                />
                                                <Button
                                                    buttonName="Save"
                                                    disabled={loadingbank}
                                                    onClick={() => handleSubmitBankDetails()}
                                                />
                                            </>
                                        ) : (
                                            <Button
                                                buttonName="Edit"
                                                disabled={loadingbank}
                                                onClick={handleClickBank}
                                            />
                                        )}
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Stack>
        </>
    );
}

export default Profileprovider;
