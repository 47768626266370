import { deleteRequest, get, post, put } from "../web.request";
export const getAllBannerApiHandler = (
  search,
  status,
  startDate,
  endDate,
  page,
  orderBy,
  flag
) => {
  return get(
    `bannerList?search=${search}&status=${status}&startDate=${startDate}&endDate=${endDate}&orderBy=${orderBy}&sortFlag=${flag}&page=${page}`
  );
};

export const addBannerApiHandler = (payload) => {
  return post(`createBanner`, payload);
};

export const EditBannerApiHandler = (id, payload) => {
  return put(`updateBanner/${id}`, payload);
};

export const deleteBannerApiHandler = (id) => {
  return deleteRequest(`deleteBanner/${id}`);
};

export const listBannerHandler = () => {
  return get(`bannerDropdown`);
};

export const statusUpdateBannerHandler = (id) => {
  return put(`statusChangeBanner/${id}`);
};
