import { combineReducers } from 'redux';
import { authReducer } from './authReducer';
import { notificationReducer } from './NotificationReducer';


const rootReducer = combineReducers({
  auth: authReducer,
  notification: notificationReducer
});

export default rootReducer;